import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { translate } from '../../../../utils/week';

function createTextHours(hours) {
  let final = '';
  let uno = `${hours[0][0]}:00 a ${hours[0][1]}:00`;
  if (hours.length === 1) final = uno;
  if (hours.length === 2)
    final = `${uno} y de ${hours[1][0]}:00 a ${hours[1][1]}:00`;
  return final;
}

export default function WeekDetail({ data, day, setDay }) {
  return (
    <List dense={true}>
      {Object.keys(data.availabilities).map((d) => {
        if (data.availabilities[d].length === 0) return;
        return (
          <ListItem key={d}>
            <ListItemText
              primary={translate[d]}
              secondary={createTextHours(data.availabilities[d])}
            ></ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}
