import React from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core';

import useStyles from './style';

export default function SubjectsButtons({
  data,
  setData,
  subjects,
  level,
  disabled,
}) {
  const classes = useStyles();

  const [chosenSubject, setChosenSubject] = React.useState('');

  const onMenuItemClick = (id) => {
    if (data.subjects.includes(id)) {
      setData({
        ...data,
        subjects: data.subjects.filter((i) => i !== id),
      });
    } else {
      setData({
        ...data,
        subjects: data.subjects.concat(id),
      });
    }
  };

  const onMenuClose = () => {
    setChosenSubject('');
  };

  const buttonClass = (subjectName) => {
    if (subjectName === chosenSubject) return classes.buttonCurrentSubject;

    let hasSelectedGrades = Object.values(
      subjects[level][subjectName]
    ).some((id) => data.subjects.includes(id));

    if (hasSelectedGrades) return classes.buttonPicked;

    return classes.buttonSubject;
  };

  return (
    <Box className={classes.containerSubjects}>
      {subjects &&
        subjects[level] &&
        Object.keys(subjects[level]).map((subjectName, i) => (
          <div key={i}>
            <Button
              id={subjectName + '-button'}
              aria-controls={subjectName + '-menu'}
              aria-haspopup="true"
              className={buttonClass(subjectName)}
              onClick={(event) => setChosenSubject(subjectName)}
              disabled={disabled}
            >
              <p>{subjectName}</p>
            </Button>
            <Menu
              id={subjectName + '-menu'}
              anchorEl={() => document.getElementById(subjectName + '-button')}
              keepMounted
              open={subjectName === chosenSubject}
              onClose={onMenuClose}
            >
              {subjects &&
                Object.keys(subjects[level][subjectName]).map((grade) => {
                  const id = subjects[level][subjectName][grade];
                  return (
                    <MenuItem key={grade} onClick={() => onMenuItemClick(id)}>
                      <Checkbox checked={data.subjects.includes(id)} />
                      <ListItemText primary={grade} />
                    </MenuItem>
                  );
                })}
            </Menu>
          </div>
        ))}
    </Box>
  );
}
