import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import ThirdForm from './ThirdForm/ThirdForm';
import { getUser } from '../../../../redux/session/actions';
import {
  getVolunteerById,
  updateVolunteer,
  getMe,
} from '../../../../service/api';
import { formattedAvailabilities } from './functions';
import validate from './validate';
import Calendar from '../../../Admin/ViewOfAdmin/TeamAssignment/Calendar/Calendar';
import VolunteerProfile from '../../../Admin/ViewOfAdmin/Volunteers/VolunteerProfile/PersonalData/VolunteerProfile';
import Swal from 'sweetalert2';

const Profile = () => {
  const sessionUser = useSelector((state) => state.SessionReducer.sessionUser);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [availabilities, setAvailabilities] = useState({});

  const setValidateData = (newData) => {
    if (typeof newData === 'function') newData = newData(data);
    const newState = { ...availabilities, ...newData.availabilities };
    setAvailabilities(newState);
    setError(validate(newState));
  };

  const [error, setError] = useState('');

  const getVolunteerData = useCallback(() => {
    if (sessionUser?.volunteerId)
      getVolunteerById(sessionUser.volunteerId).then(({ data }) =>
        setData({
          user: {
            email: data.user?.email,
            firstName: data.user?.firstName,
            lastName: data.user?.lastName,
            documentNumber: data.user?.documentNumber,
            curriculum: data.curriculum,
            documentBack: data.documentBack,
            documentFront: data.documentFront,
            linkedIn: data.linkedIn,
            status: data.status,
          },
          subjects: {
            subjects: data.subjects,
            availabilities: data.user?.availabilities || [],
          },
        })
      );
  }, [sessionUser?.volunteerId]);

  const formatAvailabilities = useCallback(() => {
    if (data?.subjects?.availabilities) {
      setAvailabilities(
        formattedAvailabilities(data?.subjects?.availabilities)
      );
    }
  }, [data?.subjects?.availabilities]);

  useEffect(() => {
    getVolunteerData();
  }, [getVolunteerData]);

  useEffect(() => {
    formatAvailabilities();
  }, [data?.subjects?.availabilities, formatAvailabilities]);

  const submitUpdateVolunteerData = () => {
    return Swal.fire({
      title: 'Modificar disponibilidad horaria',
      text: 'Confirmando esta acción, tu disponibilidad será modificada',
      icon: 'question',
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed)
          return updateVolunteer(sessionUser.volunteerId, { availabilities });
      })
      .then(({ status }) => {
        const message =
          status === 200
            ? {
                title: 'Éxito',
                text: 'Tu disponibilidad fue modificada exitosamente',
              }
            : {
                title: 'Ups',
                text: 'No han podido actualizarse correctamente los datos',
              };
        return Swal.fire(message);
      })
      .then(() => getMe())
      .then(({ data }) => dispatch(getUser(data)));
  };
  return (
    <Grid direction="column" spacing={5} style={{ textAlign: 'center' }}>
      <Grid container direction="column" spacing={5} item>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            gutterBottom
            style={{ fontFamily: '  Downward' }}
          >
            Perfil
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: '  poppins' }}
          >
            En esta sección podrás ver y modificar detalles de tu perfil.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {data?.user && data?.subjects && (
          <VolunteerProfile
            VoluntDataPersonal={data?.user}
            VoluntDataSubjects={data?.subjects}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontFamily: '  poppins' }}
        >
          Tus horarios de disponibilidad
        </Typography>
      </Grid>
      <Grid item container xs={12} justify="center">
        <Calendar volunteer={sessionUser} />
      </Grid>
      <Grid item xs={12}>
        {availabilities && (
          <ThirdForm
            student={false}
            volunteer={true}
            data={{ availabilities }}
            setData={setValidateData}
            errors={{ availabilities: error }}
            disabled={false}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={submitUpdateVolunteerData}
        >
          Guardar Cambios
        </Button>
      </Grid>
    </Grid>
  );
};

export default Profile;
