const titles = [
  {
    title: 'Sumate a nuestro equipo',
    subtitle: '¡Completá este formulario para conocerte!',
  },
  {
    title: 'Algunos documentos',
    subtitle:
      'Para continuar necesitamos saber más de vos. Opcionalmente, Currículum Vitae o URL de tu linkedIn serían ideales.',
  },
  {
    title: 'Tu disponibilidad',
    subtitle:
      'Contanos cuáles son los días y horarios en los que podrías ayudar durante una hora a la semana a un jugador de nuestro equipo.',
  },
  {
    title: 'Por último',
    subtitle:
      'Estás a un paso de unirte a nuestra causa y nos gustaría saber en qué materias y para qué años podrías dar clases de apoyo.',
  },
];

export default titles;
