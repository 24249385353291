import emailRegex from 'email-regex';

export interface errorInterface {
  [0]: {
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    mobilePhone?: string;
    email?: string;
    documentNumber?: string;
  };
  [1]: {
    firstNameTutor?: string;
    lastNameTutor?: string;
    mobilePhoneTutor?: string;
    emailTutor?: string;
    documentNumberTutor?: string;
  };
  [2]: {
    availabilities?: string;
  };
  // [3]: {
  //   subjects?: string;
  // };
  some: (fun: any) => any;
}

export default function validate(data) {
  const err: errorInterface = [{}, {}, {}, {}];

  if (!data.firstName) {
    err[0].firstName = 'Requerimos del nombre del jugador';
  }

  if (!data.lastName) {
    err[0].lastName = 'Requerimos del apellido del jugador';
  }

  // let diff = Date.now() - data.birthDate;
  // if (diff < 567648000000) {
  //   err[0].birthDate =
  //     "Debes ser mayor de 18 años para registrarte como voluntario";
  // }

  if (!data.mobilePhone) {
    err[0].mobilePhone = 'Requerimos de un telefono de contacto';
  } else if (!Number(data.mobilePhone) || data.mobilePhone.length < 10) {
    err[0].mobilePhone = 'El teléfono es inválido';
  }

  if (!data.email) {
    err[0].email = 'Requerimos de su email de contacto';
  } else if (!emailRegex({ exact: true }).test(data.email)) {
    err[0].email = 'El email es inválido';
  } else if (data.email === data.emailTutor) {
    err[0].email = 'El email del estudiante y tutor coincide';
  }

  if (!data.documentNumber) {
    err[0].documentNumber = 'Requerimos de un DNI';
  } else if (!Number(data.documentNumber) || data.documentNumber.length < 8) {
    err[0].documentNumber = 'El DNI es inválido';
  }

  if (!data.firstNameTutor) {
    err[1].firstNameTutor = 'Requerimos del nombre del jugador';
  }

  if (!data.lastNameTutor) {
    err[1].lastNameTutor = 'Requerimos del apellido del jugador';
  }

  if (!data.mobilePhoneTutor) {
    err[1].mobilePhoneTutor = 'Requerimos de un telefono de contacto';
  } else if (!Number(data.mobilePhone) || data.mobilePhone.length < 10) {
    err[1].mobilePhoneTutor = 'El teléfono es inválido';
  }

  if (!data.emailTutor) {
    err[1].emailTutor = 'Requerimos de su email de contacto';
  } else if (!emailRegex({ exact: true }).test(data.emailTutor)) {
    err[1].emailTutor = 'El email es inválido';
  } else if (data.email === data.emailTutor) {
    err[0].email = 'El email del estudiante y tutor coincide';
  }

  if (!data.documentNumberTutor) {
    err[1].documentNumberTutor = 'Requerimos de un DNI';
  } else if (
    !Number(data.documentNumberTutor) ||
    data.documentNumberTutor.length < 8
  ) {
    err[1].documentNumberTutor = 'El DNI es inválido';
  }

  if (
    !Object.keys(data.availabilities).some(
      (day) => !!data.availabilities[day].length
    )
  ) {
    err[2].availabilities = 'Marcá al menos un rango horario';
  }

  // if (!data.subjects.length) {
  //   err[3].subjects =
  //     "Seleccioná al menos una materia en la que el jugador necesita asistencia";
  // }

  return err;
}
