import React from 'react';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';

export interface CustomTextFieldPropTypes {
  name: string;
  label: string;
  value?: string;
  type?: string;
  defaultValue?: any;
  error?: boolean;
  helperText?: string;
  inputProps?: any;
  InputLabelProps?: {
    shrink: boolean;
  };
}

const CustomTextField = ({ name, ...otherProps }: CustomTextFieldPropTypes) => {
  const [field, meta] = useField(name);

  const textFieldProps = {
    fullWidth: true,
    ...field,
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    textFieldProps.error = true;
    textFieldProps.helperText = meta.error;
  }

  return <TextField variant="outlined" {...textFieldProps} />;
};

export default CustomTextField;
