import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  containerVolunteerForm: {
    width: '95%',
    justifyContent: 'center',
  },
  containerStudentForm: {
    width: '85%',
    marginBottom: '3rem',
    justifyContent: 'center',
  },
});

export default useStyles;
