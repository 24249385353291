import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import validate, { errorInterface } from './validate';
import FirstForm from '../../Forms/FirstForm/FirstForm';
import ThirdForm from '../../Forms/ThirdForm/ThirdForm';
import FourthForm from '../../Forms/FourthForm/FourthForm';
import FifthForm from '../../Forms/FifthForm/FifthForm';
import { student, onSubmit, backToStudentList } from './functions/functions';
import useStyles from './style';

const StudentCRUD = ({ volunteer }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<any>();

  const [data, setData] = useState({
    documentNumber: '',
    firstName: '',
    lastName: '',
    birthDate: new Date(),
    mobilePhone: '',
    email: '',
    grade: '1',
    firstNameTutor: '',
    lastNameTutor: '',
    mobilePhoneTutor: '',
    emailTutor: '',
    documentNumberTutor: '',
    availabilities: {},
    subjects: [],
    relation: 'tutor',
  });

  useEffect(() => {
    student(id, setData);
  }, [id]);

  const [errors, setErrors] = useState<errorInterface | undefined>([
    {},
    {},
    {},
    {},
  ]);

  const setValidateData = (newData) => {
    if (typeof newData === 'function') newData = newData(data);
    setErrors(validate(newData));
    setData(newData);
  };

  return (
    <Grid className={classes.root}>
      {id && !volunteer ? (
        <h1>
          En caso de querer editar los datos, por favor, recordá guardarlos.
        </h1>
      ) : !volunteer ? (
        <h1>Por favor, ingresá los datos del nuevo jugador</h1>
      ) : null}
      {volunteer ? (
        <h1>
          ¡Te presentamos a tu jugador! Esperamos que logren grandes jugadas
          juntos.
        </h1>
      ) : null}
      {volunteer ? (
        <h3>
          Por favor, no edites los campos. En caso de que notes un dato erróneo,
          avisanos mandandonos un mail a{' '}
          <a href={`${process.env.REACT_APP_ADMIN_EMAIL}`}>
            {process.env.REACT_APP_ADMIN_EMAIL}
          </a>
          . ¡Muchas gracias!
        </h3>
      ) : null}
      <div className={classes.containerForms}>
        <FirstForm
          student={true}
          volunteer={false}
          data={data}
          setData={setValidateData}
          errors={errors[0]}
          disabled={!!volunteer}
        />
        <FifthForm
          data={data}
          setData={setValidateData}
          errors={errors[1]}
          disabled={!!volunteer}
        />
      </div>
      <ThirdForm
        student={true}
        volunteer={false}
        data={data}
        setData={setValidateData}
        errors={errors[2]}
        disabled={false}
      />
      <FourthForm
        student={true}
        volunteer={false}
        data={data}
        setData={setValidateData}
        errors={errors[3]}
        disabled={false}
      />
      <div className={classes.buttonAddStudent}>
        {!volunteer && id ? (
          <h2>Por favor, revisá los datos antes de volver a enviar.</h2>
        ) : !volunteer ? (
          <h2>¡Qué alegría sumar un nuevo jugador al equipo!</h2>
        ) : null}
        {volunteer ? (
          <Button
            style={{ width: 'fit-content' }}
            variant="contained"
            color="primary"
            onClick={() => backToStudentList(history)}
          >
            Volver
          </Button>
        ) : (
          <Button
            style={{ width: 'fit-content' }}
            variant="contained"
            color="primary"
            onClick={() => onSubmit(data, id, errors, history)}
          >
            {id ? 'Guardar cambios' : 'Agregar jugador'}
          </Button>
        )}
      </div>
    </Grid>
  );
};

export default StudentCRUD;
