import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from '@material-ui/icons/';
import { Typography, Box } from '@material-ui/core/';

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfied />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfied />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfied />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAlt />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfied />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CustomizedRatings({
  label,
  disabled,
  onChangeData,
  name,
  value,
}) {
  return (
    <div>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">{label}</Typography>
        <Rating
          name={name}
          defaultValue={2}
          value={value}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          disabled={disabled}
          onChange={onChangeData}
        />
      </Box>
    </div>
  );
}
