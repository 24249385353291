import React from 'react';
import { TextField } from '@material-ui/core';

import InputFile from '../../InputFile/InputFile';
import useStyles from '../style';

export default function OptionalData({ data, setData, error }) {
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const classes = useStyles();
  return (
    <div className={classes.form}>
      <TextField
        value={data.linkedIn}
        name="linkedIn"
        onChange={onChange}
        label="Ingresá tu URL de LinkedIn"
        id="standard-size-small"
        size="small"
      />
      <InputFile
        value={data.curriculum}
        name={'curriculum'}
        setData={setData}
        label={'Subir currículum'}
        error={error}
      />
    </div>
  );
}
