import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '2em',
    boxShadow: '1px 2px 3px #999',
    width: '30%',
    borderRadius: '10px',
  },
  gral: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
  },
  date: {
    width: '100%',
  },
});

export default useStyles;
