import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins, sans serif',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  gral: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '90%',
    height: '50%',
  },
  input: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
  },
  date: {
    width: '300px',
  },
  container: {
    width: '80%',
    height: '60%',
    marginBottom: '10%',
    borderRadius: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  tipography: {
    marginLeft: '20px',
  },
  pass: {
    margin: '15px',
    textDecoration: 'none',
    color: '#9695A4',
    fontSize: '14px',
  },
}));

export default useStyles;
