import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import MaterialTable from 'material-table';

import Swal from 'sweetalert2';

import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AccountBoxTwoTone from '@material-ui/icons/AccountBoxTwoTone';
import Block from '@material-ui/icons/Block';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import LinkedIn from '@material-ui/icons/LinkedIn';

import CircularSpinner from '../../../../Spinners/CircularSpinner';

import {
  aceptedVolunteer,
  disableVolunteer,
  reincorporateVolunteer,
  deleteVolunteer,
  getVolunteers,
  rejectVolunteer,
} from '../../../../../service/api';
import useStyles from './styles';

const status_sp = {
  accepted: 'Activo',
  disabled: 'Receso',
  rejected: 'Rechazado',
  process: 'Pendiente',
};

const statusSortPriority = {
  process: 1,
  accepted: 2,
  disabled: 3,
  rejected: 4,
};

const VolunteersList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [volunteers, setVolunteers] = useState({
    loading: true,
    data: [],
  });
  const [shouldRefreshData, setRefreshData] = useState<boolean>(false);

  React.useEffect(() => {
    getVolunteersData();
  }, [shouldRefreshData]);

  const getVolunteersData = () => {
    getVolunteers().then(({ data }) => {
      setVolunteers({
        loading: false,
        data: data
          .map((v) => ({
            id: v.id,
            fullName: `${v?.user?.firstName ?? ''} ${v?.user?.lastName ?? ''}`,
            normailzedName: `${
              v?.user?.firstName
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') ?? ''
            } ${
              v?.user?.lastName
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') ?? ''
            }`,
            email: v?.user?.email || '-',
            mobilePhone: v.mobilePhone,
            status: v.status,
            status_sp: status_sp[v.status] ? status_sp[v.status] : v.status,
            linkedIn: v.linkedIn,
            institute: v.institute || 'Sin información',
          }))
          .sort(
            (a, b) =>
              statusSortPriority[a.status] - statusSortPriority[b.status]
          ),
      });
    });
  };

  const AcceptButton = (data) => {
    const color =
      data.status !== 'process' && data.status !== 'rejected'
        ? 'inherit'
        : 'green';

    return {
      icon: () => <Check style={{ color }} />,
      tooltip: 'Aprobar  Entrenador',
      disabled: data.status !== 'process' && data.status !== 'rejected',
      onClick: (_event, rowData) => {
        Swal.fire({
          title: 'Aceptar Nuevo Entrenador',
          text: `¿Estás seguro de aceptar a ${rowData?.firstName} ${rowData?.lastName} como nuevo entrenador?`,
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Aprobar',
          cancelButtonText: 'Cancelar',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            Swal.fire(
              'Excelente!',
              'Haz agregado un nuevo entrenador a tu equipo!',
              'success'
            );
            aceptedVolunteer(rowData.id).then(() =>
              setRefreshData((state) => !state)
            );
          } else {
            Swal.fire('Información', '¡No se aplicaron cambios!', 'info');
          }
        });
      },
    };
  };

  const RejectButton = (data) => {
    const color = data.status !== 'process' ? 'inherit' : 'secondary';

    return {
      icon: () => <Clear color={color} />,
      tooltip: 'Rechazar Entrenador',
      disabled: data.status !== 'process',
      onClick: (_event, rowData) => {
        Swal.fire({
          title: 'Rechazar Nuevo Entrenador',
          text: '¿Estás seguro que querés rechazar a este usuario ?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Rechazar',
          cancelButtonText: 'Cancelar',
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            Swal.fire('Información', '¡Rechazado Correctamente!', 'success');
            rejectVolunteer(rowData.id).then(() =>
              setRefreshData((state) => !state)
            );
          } else {
            Swal.fire('Información', '¡No se aplicaron cambios!', 'info');
          }
        });
      },
    };
  };

  const DisableButton = (data) => {
    const disabled = data.status === 'rejected';
    const text =
      data.status === 'disabled'
        ? `${data.firstName} ${data.lastName} se reincorporará del receso`
        : `¿Estás seguro que querés activar el receso de ${data.firstName} ${data.lastName}?
    Podrá seguir ingresando en la plataforma. Se desvincularán sus jugadores relacionados, como así también sus clases activas hasta tanto vuelva del receso.
  `;
    return {
      icon: () =>
        data.status === 'disabled' ? (
          <EventAvailableIcon style={{ color: 'green' }} />
        ) : (
          <EventBusyTwoToneIcon
            style={{ color: data.status === 'accepted' ? 'red' : 'pink' }}
          />
        ),
      tooltip: data.status === 'disabled' ? 'Incorporar' : 'Activar Receso',
      disabled,
      onClick: (_event, rowData) => {
        Swal.fire({
          title: 'Receso temporal',
          text,
          icon: data.status === 'disabled' ? 'info' : 'warning',
          showCancelButton: true,
          showConfirmButton: true,
        }).then(({ isConfirmed }) => {
          if (isConfirmed && data.status === 'disabled') {
            return reincorporateVolunteer(rowData.id)
              .then(() => setRefreshData((state) => !state))
              .then(() =>
                Swal.fire(
                  'Operación exitosa',
                  `${rowData.firstName} ${rowData.lastName} se reincorpora del receso`,
                  'success'
                )
              );
          } else if (isConfirmed) {
            return disableVolunteer(rowData.id)
              .then(() => setRefreshData((state) => !state))
              .then(() =>
                Swal.fire(
                  'Operación exitosa',
                  `${rowData.firstName} ${rowData.lastName} entró en receso temporal`,
                  'success'
                )
              );
          } else {
            return Swal.fire(
              'Información',
              '¡No se aplicaron cambios!',
              'info'
            );
          }
        });
      },
    };
  };

  const DeleteButton = (data) => {
    return {
      icon: () => <Block color="secondary" />,
      tooltip: 'Eliminar definitivamente',
      onClick: (_event, rowData) => {
        Swal.fire({
          title: 'Eliminar Entrenador',
          text: `¿Estás seguro que querés eliminar a ${rowData.firstName} ${rowData.lastName}?
          No podrá ingresar a la plataforma, ni será listado para asignación de equipos. Esta opción sólo debe usarse en situaciones extremas.
        `,
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            deleteVolunteer(rowData.id)
              .then(() => setRefreshData((state) => !state))
              .then(() =>
                Swal.fire(
                  'Operación exitosa',
                  `${rowData.firstName} ${rowData.lastName} fue eliminado`,
                  'success'
                )
              );
          } else {
            Swal.fire('Información', '¡No se aplicaron cambios!', 'info');
          }
        });
      },
    };
  };
  const ProfileButton = (data) => {
    return {
      icon: () => <AccountBoxTwoTone style={{ color: 'blue' }} />,
      tooltip: 'Ver Perfil',
      onClick: (_event, rowData) => {
        history.push(`/admin/entrenador/${rowData.id}`);
      },
    };
  };

  const LinkedInButton = (data) => {
    const color = data.linkedIn === '' ? 'inherit' : 'blue';
    return {
      icon: () => <LinkedIn style={{ color }} />,
      disabled: !data.linkedIn,
      tooltip: 'Ver LinkedIn',
      onClick: (event, rowData) => window.open(rowData.linkedIn, '_blank'),
    };
  };

  return !volunteers.loading && volunteers.data ? (
    <Grid container direction="column" spacing={10}>
      <Grid direction="column" item spacing={5} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            gutterBottom
            style={{ fontFamily: '  Downward' }}
          >
            Entrenadores
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: '  poppins' }}
          >
            En esta sección podrás ver y gestionar el estado de tus
            entrenadores.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" justify="center" alignItems="center">
        <Grid item sm={12}>
          {/* Material table */}
          <MaterialTable
            title="Entrenadores"
            columns={[
              {
                title: 'Nombre',
                field: 'fullName',
                customSort: (a, b) =>
                  a.normailzedName
                    ?.toLowerCase()
                    .localeCompare(b.normailzedName?.toLowerCase()),
              },
              {
                title: 'Email',
                field: 'email',
                customSort: (a, b) =>
                  a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
              },
              { title: 'Institución', field: 'institute' },
              { title: 'Telefono', field: 'mobilePhone' },
              {
                title: 'Estado',
                field: 'status_sp',
                render: (rowData) => (
                  <Badge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className={
                      rowData.status === 'accepted'
                        ? classes.badgeActive
                        : rowData.status === 'disabled'
                        ? classes.badgeInactive
                        : rowData.status === 'process'
                        ? classes.badgePending
                        : classes.badgeRejected
                    }
                    color="primary"
                    variant="dot"
                  >
                    <Typography>{rowData.status_sp}</Typography>
                  </Badge>
                ),
              },
            ]}
            data={volunteers.data}
            actions={[
              AcceptButton,
              RejectButton,
              DisableButton,
              ProfileButton,
              LinkedInButton,
              DeleteButton,
            ]}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              searchFieldVariant: 'outlined',
              actionsColumnIndex: -1,
              rowStyle: { fontFamily: 'poppins' },
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsPerPage: 'Filas por página',
                lastTooltip: 'Última página',
                firstTooltip: 'Primera página',
                nextTooltip: 'Página siguiente',
                previousTooltip: 'Página anterior',
                labelRowsSelect: 'Filas',
              },
              body: {
                emptyDataSourceMessage: 'No se han encontrado entrenadores',
                filterRow: {
                  filterTooltip: 'Filtro',
                },
              },
              toolbar: {
                searchPlaceholder: 'Buscar Entrenador',
              },
              header: {
                actions: 'Acciones',
              },
            }}
            style={{
              padding: '15px',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <CircularSpinner />
  );
};
export default VolunteersList;
