import * as Yup from 'yup';
import differenceInYears from 'date-fns/differenceInYears';

export default Yup.object().shape({
  firstName: Yup.string().required('Requerimos su primer nombre'),
  institute: Yup.string(),
  lastName: Yup.string().required('Requerimos su apellido'),
  mobilePhone: Yup.number()
    .typeError('Teléfono inválido')
    .required('Requerimos de un teléfono de contacto'),
  email: Yup.string()
    .email('Email inválido')
    .required('Requerimos de su email de contacto'),
  birthDate: Yup.date()
    .typeError('Fecha inválida')
    .test(
      'birthDate',
      'Debe ser mayor de edad',
      (value) => differenceInYears(new Date(), new Date(value)) >= 18
    )
    .required('Requerimos su fecha de nacimiento'),
});
