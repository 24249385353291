import React from 'react';
import { Grid, Button, Fade, Backdrop, Modal } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Detail from './Detail';
import { useStyles } from './style';

export default function TransitionsModal({ data, subjects, errors }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return errors.subjects ? (
    <Grid>
      <Alert severity="error">{errors.subjects}</Alert>
    </Grid>
  ) : (
    <Grid>
      <Grid className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Ver Materias Seleccionadas
        </Button>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid className={classes.paper}>
            <Grid style={{ alignSelf: 'flex-end' }}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cerrar
              </Button>
            </Grid>
            <h4> Materias Seleccionadas: </h4>
            <Detail data={data} subjects={subjects} errors={errors} />
          </Grid>
        </Fade>
      </Modal>
    </Grid>
  );
}
