export const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const cells = (hora, volunteer, day, style) => {
  let classNames = [];

  if (!volunteer.availabilities[day]) {
    return null;
  } else {
    if (volunteer.availabilities[day][0] && volunteer.availabilities[day][1]) {
      if (
        hora === volunteer.availabilities[day][0][0] ||
        hora === volunteer.availabilities[day][1][0]
      ) {
        classNames.push(style.start);
      }

      if (
        (hora >= volunteer.availabilities[day][0][0] &&
          hora < volunteer.availabilities[day][0][1]) ||
        (hora >= volunteer.availabilities[day][1][0] &&
          hora < volunteer.availabilities[day][1][1])
      ) {
        classNames.push(style.cell);
      }

      if (
        hora === volunteer.availabilities[day][0][1] - 1 ||
        hora === volunteer.availabilities[day][1][1] - 1
      ) {
        classNames.push(style.end);
      }
    } else {
      if (!volunteer.availabilities[day][1]) {
        if (volunteer.availabilities[day][0]) {
          if (hora === volunteer.availabilities[day][0][0]) {
            classNames.push(style.start);
          }

          if (
            hora >= volunteer.availabilities[day][0][0] &&
            hora < volunteer.availabilities[day][0][1]
          ) {
            classNames.push(style.cell);
          }

          if (hora === volunteer.availabilities[day][0][1] - 1) {
            classNames.push(style.end);
          }
        }
      }

      if (!volunteer.availabilities[day][0]) {
        if (volunteer.availabilities[day][1]) {
          if (hora === volunteer.availabilities[day][1][0]) {
            classNames.push(style.start);
          }

          if (
            hora >= volunteer.availabilities[day][1][0] &&
            hora < volunteer.availabilities[day][1][1]
          ) {
            classNames.push(style.cell);
          }

          if (hora === volunteer.availabilities[day][1][1] - 1) {
            classNames.push(style.end);
          }
        }
      }
    }
  }

  return classNames.join(' ');
};
