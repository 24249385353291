import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    // height: "70%",
    // justifyContent: "space-between",
    padding: '2em',
    boxShadow: '1px 2px 3px #999',
    borderRadius: '10px',
  },
});

export default useStyles;
