import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import WeekDetail from '../../WeekDetail/WeekDetail';
import { useStyles } from './style';

export default function TransitionsModal({ data, day, setDay, errors }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return errors.availabilities ? (
    <div style={{ height: 37.5 }}>
      <Alert severity="error">{errors.availabilities}</Alert>
    </div>
  ) : (
    <div>
      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Ver horarios seleccionados
        </Button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={{ alignSelf: 'flex-end' }}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cerrar
              </Button>
            </div>
            <h4> Horarios seleccionados: </h4>
            <WeekDetail data={data} day={day} setDay={setDay} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
