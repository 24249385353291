import React from 'react';
import Button from '@material-ui/core/Button';
import useStyle from './style';

const ButtonStepper = ({ step, setStep, errors, onSubmit }) => {
  const classes = useStyle();
  const handleNext = () => setStep(step > 2 ? 3 : step + 1);
  const handleBack = () => setStep(step < 1 ? 0 : step - 1);

  return (
    <div className={classes.button}>
      <Button
        onClick={handleBack}
        disabled={step < 1}
        className={classes.backButton}
      >
        Atrás
      </Button>
      <Button
        disabled={!!Object.keys(errors[step]).length}
        onClick={step > 2 ? onSubmit : handleNext}
        variant="contained"
        color="primary"
        className={classes.backButton}
      >
        {step > 2 ? 'Enviar' : 'Siguiente'}
      </Button>
    </div>
  );
};

export default ButtonStepper;
