import React from 'react';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core/';

export default function RadioButton({
  label,
  data,
  idSi,
  idNo,
  onChangeData,
  name,
}) {
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name={name}
          value={data.accompanying === 'true' ? 'true' : 'false'}
        >
          <FormControlLabel
            value={idSi}
            control={
              <Radio color="primary" disabled={data.assisted === 'yes'} />
            }
            label="Si"
            labelPlacement="top"
            onChange={onChangeData}
            disabled={!(data?.assisted === 'yes')}
          />
          <FormControlLabel
            value={idNo}
            control={
              <Radio color="primary" disabled={data.assisted === 'yes'} />
            }
            label="No"
            labelPlacement="top"
            onChange={onChangeData}
            disabled={!(data?.assisted === 'yes')}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
