import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AccountBoxTwoTone from '@material-ui/icons/AccountBoxTwoTone';
import Block from '@material-ui/icons/Block';
import {
  getStudents,
  getMe,
  myStudents,
  changeStudentStatus,
  deleteStudentById,
} from '../../../service/api';
import Badge from '@material-ui/core/Badge';
import Swal from 'sweetalert2';
import useStyles from './styles';

const status_sp = {
  active: 'Activo',
  disabled: 'Inactivo',
};

const StudentsList = ({ volunteer }) => {
  const classes = useStyles();
  const history = useHistory();
  const [studentData, setStudentData] = React.useState([]);
  const [shouldRefreshData, setRefreshData] = useState<boolean>(false);

  useEffect(() => {
    if (volunteer) {
      getMe()
        .then(({ data: user }) => myStudents(user?.volunteerId))
        .then(({ data: students }) => {
          if (!students) return;
          setStudentData(
            students.map((student) => ({
              id: student.id,
              mobilePhone: student.mobilePhone,
              etiquetas: 'status',
              status: student.status,
              status_sp: status_sp[student.status]
                ? status_sp[student.status]
                : student.status,
              ...student.user,
            }))
          );
        });
    } else {
      getStudents().then(({ data: students }) => {
        if (!students) return;
        setStudentData(
          students.map((student) => ({
            ...student.user,
            fullName: `${student?.user?.firstName ?? ''} ${
              student?.user?.lastName ?? ''
            }`,
            normailzedName: `${
              student?.user?.firstName
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') ?? ''
            } ${
              student?.user?.lastName
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '') ?? ''
            }`,
            mobilePhone: student.mobilePhone,
            etiquetas: 'status',
            status: student.status,
            status_sp: status_sp[student.status]
              ? status_sp[student.status]
              : student.status,
            id: student.id,
          }))
        );
      });
    }
  }, [volunteer, shouldRefreshData]);

  const addStudents = () => {
    history.push(`/admin/nuevo-jugador`);
  };
  const FeedbacksButton = (data) => {
    return {
      icon: () => <AssignmentIcon style={{ color: 'blueviolet' }} />,
      tooltip: !volunteer && 'Ver Feedbacks',
      onClick: (event, rowData) =>
        history.push(`/admin/jugadores/informes/${rowData.id}`),
    };
  };

  const ProfileButton = (data) => {
    return {
      icon: () => <AccountBoxTwoTone style={{ color: 'blue' }} />,
      tooltip: 'Ver Perfil',
      onClick: (event, rowData) => {
        volunteer
          ? history.push(`/entrenador/jugador/${rowData.id}`)
          : history.push(`/admin/jugador/${rowData.id}`);
      },
    };
  };
  const ChangeStatusButton = (rowData) => {
    const color = rowData.status === 'active' ? 'red' : 'green';
    const text =
      rowData.status === 'disabled'
        ? `${rowData.firstName} ${rowData.lastName} se reincorporará del receso`
        : `¿Estás seguro que querés activar el receso de ${rowData.firstName} ${rowData.lastName}?
    Se desvincularán sus entrenadores relacionados, como así también sus clases activas hasta tanto vuelva del receso.
  `;
    return {
      icon: () =>
        rowData.status === 'disabled' ? (
          <EventAvailableIcon style={{ color }} />
        ) : (
          <EventBusyTwoToneIcon style={{ color }} />
        ),
      tooltip:
        rowData.status === 'active'
          ? 'Receso de estudiante'
          : 'Reincorporar estudiante',
      onClick: (_event, rowData) => {
        Swal.fire({
          title: 'Receso temporal',
          text,
          icon: rowData.status === 'disabled' ? 'info' : 'warning',
          showCancelButton: true,
          showConfirmButton: true,
        }).then(({ isConfirmed }) => {
          if (isConfirmed && rowData.status === 'disabled') {
            return changeStudentStatus(rowData.id, 'active')
              .then(() => setRefreshData((state) => !state))
              .then(() =>
                Swal.fire(
                  'Operación exitosa',
                  `${rowData.firstName} ${rowData.lastName} se reincorpora del receso`,
                  'success'
                )
              );
          } else if (isConfirmed) {
            return changeStudentStatus(rowData.id, 'disabled')
              .then(() => setRefreshData((state) => !state))
              .then(() =>
                Swal.fire(
                  'Operación exitosa',
                  `${rowData.firstName} ${rowData.lastName} entró en receso temporal`,
                  'success'
                )
              );
          } else {
            return Swal.fire(
              'Información',
              '¡No se aplicaron cambios!',
              'info'
            );
          }
        });
      },
    };
  };

  const DeleteButton = (data) => {
    return {
      icon: () => <Block color="secondary" />,
      tooltip: 'Eliminar definitivamente',
      onClick: (_event, rowData) => {
        Swal.fire({
          title: 'Eliminar Estudiante',
          text: `¿Estás seguro que querés eliminar a ${rowData.firstName} ${rowData.lastName}?
            Esta opción sólo debe usarse en situaciones extremas.
        `,
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            deleteStudentById(rowData.id)
              .then(() => setRefreshData((state) => !state))
              .then(() =>
                Swal.fire(
                  'Operación exitosa',
                  `${rowData.firstName} ${rowData.lastName} fue eliminado`,
                  'success'
                )
              );
          } else {
            Swal.fire('Información', '¡No se aplicaron cambios!', 'info');
          }
        });
      },
    };
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid spacing={5} style={{ textAlign: 'center' }}>
        <Grid item>
          <Typography
            variant="h3"
            gutterBottom
            style={{ fontFamily: '  Downward' }}
          >
            Jugadores
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: '  poppins' }}
          >
            En esta sección podrás ver tus jugadores y sus detalles .
          </Typography>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        {volunteer ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={addStudents}
            style={{ marginBottom: '15px' }}
          >
            Agregar jugador
          </Button>
        )}
        {/* Material table */}
        <MaterialTable
          title="Lista de Jugadores"
          columns={[
            {
              title: 'Nombre',
              field: 'fullName',
              customSort: (a, b) =>
                a.normailzedName
                  ?.toLowerCase()
                  .localeCompare(b.normailzedName?.toLowerCase()),
            },
            {
              title: 'Email',
              field: 'email',
              customSort: (a, b) =>
                a.email?.toLowerCase().localeCompare(b.email?.toLowerCase()),
            },
            { title: 'Teléfono', field: 'mobilePhone' },
            {
              title: 'Estado',
              field: 'status_sp',
              render: (rowData) => (
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className={
                    rowData.status === 'active'
                      ? classes.badgeActive
                      : classes.badgeInactive
                  }
                  color="primary"
                  variant="dot"
                >
                  <Typography>{rowData.status_sp}</Typography>
                </Badge>
              ),
            },
          ]}
          data={studentData}
          actions={[
            ProfileButton,
            !volunteer && FeedbacksButton,
            !volunteer && ChangeStatusButton,
            !volunteer && DeleteButton,
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
            searchFieldVariant: 'outlined',
            actionsColumnIndex: -1,
            rowStyle: { fontFamily: 'poppins' },
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsPerPage: 'Filas por página',
              lastTooltip: 'Última página',
              firstTooltip: 'Primera página',
              nextTooltip: 'Página siguiente',
              previousTooltip: 'Página anterior',
              labelRowsSelect: 'Filas',
            },
            body: {
              emptyDataSourceMessage: 'No se han encontrado jugadores',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
            toolbar: {
              searchPlaceholder: 'Buscar Jugador',
            },
            header: {
              actions: 'Acciones',
            },
          }}
          style={{
            padding: '15px',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default StudentsList;
