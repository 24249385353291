import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  backButton: {
    marginRight: theme.spacing(1),
    borderRadius: '999px',
    textTransform: 'none',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    marginTop: '30px',
    borderRadius: '999px',
    textTransform: 'none',
    fontSize: '14px',
  },
}));

export default useStyles;
