import React from 'react';
import {
  Select,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Box,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from './style';

export default function FirstForm({
  student,
  volunteer,
  data,
  setData,
  errors,
  disabled,
}) {
  const classes = useStyles();

  const onChange = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const onBirthDateChange = (birthDate) => {
    setData((data) => ({ ...data, birthDate }));
  };

  return (
    <FormControl
      className={student ? classes.root : classes.gral}
      disabled={disabled}
    >
      {student ? <h3>Datos básicos</h3> : null}
      {student ? (
        <TextField
          type="number"
          value={data.documentNumber}
          name="documentNumber"
          onChange={onChange}
          label="Número de Documento"
          id="standard-size-small"
          size="small"
          disabled={disabled}
          error={errors.documentNumber}
          helperText={errors.documentNumber}
        />
      ) : null}{' '}
      {/* El documento en el formulario de voluntario se pide en el segundo form*/}
      <TextField
        id="firstName-input"
        name="firstName"
        label="Nombre"
        value={data.firstName}
        onChange={onChange}
        disabled={disabled}
        error={errors.firstName}
        helperText={errors.firstName}
      />
      <TextField
        id="lastName-input"
        name="lastName"
        label="Apellido"
        value={data.lastName}
        onChange={onChange}
        disabled={disabled}
        error={errors.lastName}
        helperText={errors.lastName}
      />
      <TextField
        id="mobilePhone-input"
        name="mobilePhone"
        label="Celular (sin el 0 ni el 15)"
        value={data.mobilePhone}
        onChange={onChange}
        disabled={disabled}
        error={errors.mobilePhone}
        helperText={errors.mobilePhone}
      />
      <TextField
        id="email-input"
        name="email"
        label="Email"
        value={data.email}
        onChange={onChange}
        disabled={disabled}
        error={errors.email}
        helperText={errors.email}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            disabled={disabled}
            id="birthDate-input"
            name="birthDate"
            label="Fecha de nacimiento"
            value={data.birthDate}
            onChange={onBirthDateChange}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            invalidDateMessage="Selecciona una fecha valida"
            className={classes.date}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      {student ? (
        <Box>
          <FormControl style={{ width: '100%' }} disabled={disabled}>
            <InputLabel id="select-label">Grado</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={data.grade}
              name="grade"
              onChange={onChange}
              disabled={disabled}
            >
              <MenuItem value={'1'}>Primaria</MenuItem>
              <MenuItem value={'2'}>1ro Secundaria</MenuItem>
              <MenuItem value={'3'}>2do Secundaria</MenuItem>
              <MenuItem value={'4'}>3ro Secundaria</MenuItem>
              <MenuItem value={'5'}>4to Secundaria</MenuItem>
              <MenuItem value={'6'}>5to Secundaria</MenuItem>
              <MenuItem value={'7'}>6to Secundaria</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : null}
    </FormControl>
  );
}
