import React from 'react';
import { Box, CssBaseline } from '@material-ui/core';
import useStyles from './Style';
import Navbar from './DashboardComponents/Navbar/Navbar';
import DrawerLeft from './DashboardComponents/DrawerLeft/DrawerLeft';
import RoutesComponents from './DashboardComponents/RoutesComponents/RoutesComponents';

export default function DashboardContainer() {
  const classes = useStyles();

  //State showHide
  const [open, setOpen] = React.useState(false);

  // Function showHide
  const handleDrawerOpenClose = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      <CssBaseline />
      {/* Navigation */}
      <Navbar showHide={handleDrawerOpenClose} state={open} />
      {/* Drawer Left */}
      <DrawerLeft showHide={handleDrawerOpenClose} state={open} />
      {/* Routes of Render Components Right */}
      <RoutesComponents />
    </Box>
  );
}
