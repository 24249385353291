import React from 'react';
import { Grid, ButtonBase } from '@material-ui/core';
import Image from '../../../../static/images/letrasLogo.png';
import useStyle from './style';
import Image2 from '../../../../static/images/imageHeaderVolunteer.png';
export default function Header() {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.root}>
        <Grid item className={classes.responsive}>
          <ButtonBase className={classes.image}>
            <img className={classes.img} alt="complex" src={Image2} />
          </ButtonBase>
          <Grid>
            <img className={classes.image} alt="complex" src={Image} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
