import React from 'react';
import DashboardVolunteer from '../../components/Volunteer/Navigation/Navigation';
import { Box } from '@material-ui/core';

import useStyles from './Style';

const AdminContainer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <DashboardVolunteer />;
    </Box>
  );
};

export default AdminContainer;
