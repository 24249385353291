import { LOGIN, LOGOUT, SESSION_LOADING, GET_USER } from './constants';

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  roles: [],
  sessionUser: {},
};

export default function sessionReducer(state = initialState, action) {
  const roles = action?.payload?.roles;
  switch (action.type) {
    case LOGIN:
      return {
        isLoading: false,
        isAuthenticated: true,
        roles: roles ? roles : [],
        sessionUser: action.payload,
      };
    case LOGOUT:
      return {
        isLoading: false,
        isAuthenticated: false,
        roles: [],
        sessionUser: {},
      };
    case SESSION_LOADING:
      return {
        ...state,
        isLoading: !!action.payload,
      };
    case GET_USER:
      return {
        isLoading: false,
        isAuthenticated: true,
        roles: roles ? roles : [],
        sessionUser: action.payload,
      };

    default:
      return state;
  }
}
