import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxHeight: '100vh',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      width: '100%',
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  subForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    minHeight: '70%',
    padding: '2%',
    paddingTop: '1%',
    borderRadius: '5px',
    boxShadow: '1px 0px 3px 2px #999',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
  },
  step4: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '3%',
    borderRadius: '5px',
  },
}));

export default useStyles;
