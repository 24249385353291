import React, { useState } from 'react';
import { Grid, Grow } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';

import useStyles from './style';
import Picture from './Picture/Picture';
import ButtonStepper from './ButtonStepper/ButtonStepper';
import HeaderForm from './HeaderForm/HeaderForm';
import StepperForm from './StepperForm/StepperForm';
import FirstForm from './NewForms/UserInfo/UserInfo';
import SecondForm from '../Forms/SecondForm/SecondForm';
import ThirdForm from '../Forms/ThirdForm/ThirdForm';
import FourthForm from '../Forms/FourthForm/FourthForm';
import validate, { errorInterface } from './validate';
import { postRegister } from '../../service/api';

export default function VolunteerForm() {
  const history = useHistory();
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    mobilePhone: '',
    email: '',
    linkedIn: '',
    documentNumber: '',
    institute: '',
    documentFront: null,
    documentBack: null,
    curriculum: null,
    availabilities: {},
    subjects: [],
  });

  const [errors, setErrors] = useState<errorInterface | undefined>(
    validate(data)
  );
  const setValidateData = (newData) => {
    if (typeof newData === 'function') newData = newData(data);
    newData.birthDate = new Date(newData.birthDate);
    setErrors(validate(newData));
    setData(newData);
  };
  const onSubmit = () => {
    if (errors.some((err) => !!Object.keys(err).length)) {
      return;
    } else {
      swal({
        title: `Por favor, leé nuestros términos y condiciones para poder continuar. Te enviaremos el detalle por mail al terminar la postulación.`,
        text: `Por la presente me inscribo a participar como voluntario asesor en el proyecto de Deserción Escolar que brinda la Fundación El Potrero. Manifiesto que he recibido toda la información necesaria de forma clara, comprensible y satisfactoria, sobre la naturaleza y el propósito de los objetivos y procedimientos del proceso a seguir. Me comprometo a formar parte del grupo de voluntarios, lo cual supone el cumplimiento de las supervisiones y las horas destinadas al acompañamiento del alumno. Asimismo, me comprometo a dar cuenta del seguimiento de mi/s asesorados y a todo lo que sea solicitado por mi supervisor. Considero que he tenido la oportunidad/es de plantear mis dudas y evaluar la naturaleza de este proyecto. Acepto que las sesiones de apoyo on-line se llevarán a cabo mediante la plataforma Meet siguiendo las recomendaciones de la Agencia de Acceso a la Información Pública para cuidar la privacidad de los niños. Se propiciará que se lleven a cabo en un entorno de privacidad en la que, salvo acuerdo, no habrá terceras personas participantes y se evitarán interrupciones. Acepto que las sesiones no podrán ser grabadas. Declaro haber sido informado(a) que mi participación en este estudio será completamente voluntaria y que no recibiré ningún tipo de retribución económica por ella. Entiendo, además, que tengo derecho a dejar mi participación cuando termina el cuatrimestre o ante situaciones justificadas. 
        Por la presente, doy mi consentimiento libre, expreso e informado en los términos de la Ley de Protección de Datos Personales N°25.326 para que Fundación El Potrero recopile, procese y utilice la información personal para fines de seguimiento. Declaro conocer que la provisión de la información requerida es voluntaria y me comprometo a proporcionar en todo momento   información verdadera y exacta, debiendo asimismo mantenerla actualizada en todo momento.  Asimismo, entiendo que la falta de cooperación y/o la falsedad o inexactitud de la información entregada podría afectar las tareas de apoyo, estadística e investigación brindadas por la Fundación. A fin de ejercer los derechos de acceso, rectificación y supresión de datos, podré enviar una solicitud a la casilla de correo electrónico ${process.env.REACT_APP_ADMIN_EMAIL}. Los datos proporcionados serán almacenados en una base de datos debidamente inscripta por la Fundación El Potrero ante la Agencia de Acceso a la Información Pública. En cumplimiento de la Resolución 14/2018 se me informa que “La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.” Por todo lo expuesto, y en función de lo que se me ha explicado, presto mi conformidad/ consentimiento para participaren este espacio de acompañamiento escolar, aceptando a su vez presentarme siempre que se me lo requiera.
        `,
        icon: 'info',
        buttons: ['Cancelar', 'Estoy de acuerdo'],
      }).then((rta) => {
        if (rta) {
          swal({
            title: `Confirmá tu email`,
            text: `¿Está correcto tu email? ${data.email}`,
            icon: 'info',
            buttons: ['No', 'Sí'],
          }).then((rta) => {
            if (rta) {
              let info = {
                ...data,
                documentNumber: Number(data.documentNumber),
                mobilePhone: Number(data.mobilePhone),
              };

              delete info.documentFront;
              delete info.documentBack;
              delete info.curriculum;

              let formData = new FormData();

              formData.append('info', JSON.stringify(info));
              formData.append('documentFront', data.documentFront);
              formData.append('documentBack', data.documentBack);
              formData.append('curriculum', data.curriculum);
              postRegister(formData);
              swal({
                title: `Excelente`,
                text: `Pronto nos comunicaremos con vos, te enviamos un email con los datos que ingresaste. En caso de no recibirlo, por favor, escribinos a ${process.env.REACT_APP_ADMIN_EMAIL}.`,
                icon: 'success',
                buttons: ['Aceptar'],
              }).then(() => history.push('/'));
            } else {
              swal({
                title: `Esperá`,
                text:
                  'Volvé al formulario y revisá que tus datos estén correctos',
                icon: 'info',
                buttons: ['Aceptar'],
              });
            }
          });
        } else {
          swal({
            title: `¡Esperamos volver a verte pronto!`,
            text:
              'Lamentamos que no quieras participar en Fundación El Potrero. Ante cualquier duda o consulta, podés escribirnos a admin@elpotrero.com.',
            icon: 'info',
            buttons: ['Aceptar'],
          }).then(() => history.push('/'));
        }
      });
    }
  };

  return (
    <Grid className={classes.root}>
      <Picture />
      <Grid className={classes.container}>
        <HeaderForm step={step} />
        <Grid className={classes.form}>
          <Grow
            in={true}
            style={{ transformOrigin: '50 50 50' }}
            {...(true ? { timeout: 1000 } : {})}
          >
            <>
              <StepperForm step={step} />
              <Grid
                container
                className={step === 3 ? classes.step4 : classes.subForm}
              >
                <Grid container>
                  {step === 0 && (
                    <FirstForm setData={setValidateData} setStep={setStep} />
                  )}
                  {step === 1 && (
                    <SecondForm
                      data={data}
                      setData={setValidateData}
                      errors={errors[1]}
                    />
                  )}
                  {step === 2 && (
                    <ThirdForm
                      student={false}
                      volunteer={true}
                      data={data}
                      setData={setValidateData}
                      errors={errors[2]}
                      disabled={false}
                    />
                  )}
                  {step === 3 && (
                    <FourthForm
                      student={false}
                      volunteer={true}
                      data={data}
                      setData={setValidateData}
                      errors={errors[3]}
                      disabled={false}
                    />
                  )}
                </Grid>
                {step !== 0 && (
                  <Grid spacing={2} style={{ justifyContent: 'center' }}>
                    <ButtonStepper
                      step={step}
                      setStep={setStep}
                      errors={errors}
                      onSubmit={onSubmit}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );
}
