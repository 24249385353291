export const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const cells = (hora, role, day, style) => {
  let classNames = [];
  const user = formatAvailabilities(role);
  if (!user.availabilities[day]) {
    return null;
  } else {
    if (user.availabilities[day][0] && user.availabilities[day][1]) {
      if (
        hora === user.availabilities[day][0][0] ||
        hora === user.availabilities[day][1][0]
      ) {
        classNames.push(style.start);
      }

      if (
        (hora >= user.availabilities[day][0][0] &&
          hora < user.availabilities[day][0][1]) ||
        (hora >= user.availabilities[day][1][0] &&
          hora < user.availabilities[day][1][1])
      ) {
        user.role === 'student'
          ? classNames.push(style.studentCell)
          : classNames.push(style.volunteerCell);
      }

      if (
        hora === user.availabilities[day][0][1] - 1 ||
        hora === user.availabilities[day][1][1] - 1
      ) {
        classNames.push(style.end);
      }
    } else {
      if (!user.availabilities[day][1]) {
        if (user.availabilities[day][0]) {
          if (hora === user.availabilities[day][0][0]) {
            classNames.push(style.start);
          }

          if (
            hora >= user.availabilities[day][0][0] &&
            hora < user.availabilities[day][0][1]
          ) {
            user.role === 'student'
              ? classNames.push(style.studentCell)
              : classNames.push(style.volunteerCell);
          }

          if (hora === user.availabilities[day][0][1] - 1) {
            classNames.push(style.end);
          }
        }
      }

      if (!user.availabilities[day][0]) {
        if (user.availabilities[day][1]) {
          if (hora === user.availabilities[day][1][0]) {
            classNames.push(style.start);
          }

          if (
            hora >= user.availabilities[day][1][0] &&
            hora < user.availabilities[day][1][1]
          ) {
            user.role === 'student'
              ? classNames.push(style.studentCell)
              : classNames.push(style.volunteerCell);
          }

          if (hora === user.availabilities[day][1][1] - 1) {
            classNames.push(style.end);
          }
        }
      }
    }
  }

  return classNames.join(' ');
};

export function formatAvailabilities(user) {
  return {
    role: user.role,
    availabilities: user.availabilities.reduce(
      (availabilities, availability) => {
        const { day, start, end } = availability;
        if (!availabilities[day]) availabilities[day] = [];
        availabilities[day].push([start, end]);
        return availabilities;
      },
      {}
    ),
  };
}
