import React, { useState } from 'react';
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FeedbackModal from '../../../common/FeedbackModal';
import { getStudentFeedbacks } from '../../../../service/api';

export default function StudentFeedbacks({
  match: {
    params: { studentId },
  },
}) {
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackInfo, setFeedbackInfoModal] = useState({
    open: false,
    feedbackDetail: null,
  });

  React.useEffect(() => {
    const assisted_sp = {
      'no justified': 'falta no justificada',
      justified: 'falta justificada',
      yes: 'asistió',
    };
    if (studentId) {
      getStudentFeedbacks(studentId).then(({ data: feedbacks }) =>
        setFeedbacks(
          feedbacks.map((feedback) => ({
            ...feedback,
            date: feedback.date.split('T')[0],
            start: `${feedback.lesson.start}:00hs`,
            end: `${feedback.lesson.end}:00hs`,
            assisted: feedback.assisted,
            assisted_sp: assisted_sp[feedback.assisted]
              ? assisted_sp[feedback.assisted]
              : feedback.assisted,
            concentration: feedback.concentration,
            internetConnection: feedback.internetConnection,
            attitude: feedback.attitude,
            performance: feedback.performance,
            accompanying: feedback.accompanying ? 'si' : 'no',
            volunteerFirstName: feedback.lesson.volunteer.user?.firstName,
            volunteerLastName: feedback.lesson.volunteer.user?.lastName,
          }))
        )
      );
    }
  }, [studentId]);

  const handleCloseFeedbackModal = () =>
    setFeedbackInfoModal({ open: false, feedbackDetail: null });

  return (
    <>
      <MaterialTable
        title="Lista de Informes"
        columns={[
          { title: 'Fecha', field: 'date' },
          { title: 'Hora de inicio', field: 'start' },
          { title: 'Hora de finalización', field: 'end' },
          { title: 'Nombre', field: 'volunteerFirstName' },
          { title: 'Apellido', field: 'volunteerLastName' },
          { title: 'Asistencia', field: 'assisted_sp' },
          { title: 'Concentración', field: 'concentration' },
          { title: 'Conexión de internet', field: 'internetConnection' },
          { title: 'Desempeño', field: 'performance' },
          { title: 'Acompañamiento', field: 'accompanying' },
        ]}
        actions={[
          {
            icon: () => <VisibilityIcon color="primary" />,
            tooltip: 'Ver informe',
            onClick: (_event, rowData) =>
              setFeedbackInfoModal({
                open: true,
                feedbackDetail: feedbacks.find(
                  (feedback) => feedback.id === rowData.id
                ),
              }),
          },
        ]}
        data={feedbacks}
        options={{
          exportButton: true,
          searchFieldVariant: 'outlined',
          actionsColumnIndex: -1,
          rowStyle: { fontFamily: 'poppins' },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsPerPage: 'Filas por página',
            lastTooltip: 'Última página',
            firstTooltip: 'Primera página',
            nextTooltip: 'Página siguiente',
            previousTooltip: 'Página anterior',
            labelRowsSelect: 'Filas',
          },
          body: {
            emptyDataSourceMessage: 'No se han encontrado informes',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
          toolbar: {
            searchPlaceholder: 'Buscar informes',
          },
          header: {
            actions: 'Informe ',
          },
        }}
      />
      <FeedbackModal
        feedbackInfo={feedbackInfo}
        onClose={handleCloseFeedbackModal}
      />
    </>
  );
}
