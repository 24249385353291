import {
  getStudentById,
  getTutorById,
  modifyStudent,
  modifyTutor,
  postStudent,
} from '../../../../service/api';

export const student = (id, setData) => {
  if (id) {
    (async () => {
      const student = await getStudentById(id);
      let subjects = [];
      student.data.subjects.map((e) => subjects.push(e.id));

      const formattedAvailabilities = (availability) => {
        const availabilities = {};
        availability?.forEach((e) => {
          if (!availabilities.hasOwnProperty(e.day)) {
            availabilities[e.day] = [];
          }
          availabilities[e.day].push([e.start, e.end]);
        });
        return availabilities;
      };

      const tutor =
        student.data?.tutor?.id &&
        (await getTutorById(student.data?.tutor?.id));
      setData({
        documentNumber: student.data.user?.documentNumber,
        firstName: student.data.user?.firstName,
        lastName: student.data.user?.lastName,
        birthDate: student.data.birthDate,
        mobilePhone: student.data.mobilePhone,
        email: student.data.user?.email,
        grade: student.data.grade.id,
        firstNameTutor: tutor?.data?.user?.firstName,
        lastNameTutor: tutor?.data?.user?.lastName,
        mobilePhoneTutor: tutor?.data?.mobilePhone,
        emailTutor: tutor?.data?.user?.email,
        documentNumberTutor: tutor?.data?.user?.documentNumber,
        availabilities: formattedAvailabilities(
          student.data.user?.availabilities
        ),
        subjects: subjects,
        relation: student.data.relation,
      });
    })();
  }
};

export const onSubmit = (data, id, errors, history) => {
  if (errors.some((err) => !!Object.keys(err).length)) return;
  if (id) {
    modifyStudent(
      {
        documentNumber: Number(data.documentNumber),
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: data.birthDate,
        email: data.email,
        mobilePhone: data.mobilePhone,
        grade: data.grade,
        availabilities: data.availabilities,
        subjects: data.subjects,
        relation: data.relation,
      },
      id
    )
      .then(async () => {
        const student = await getStudentById(id);
        modifyTutor(
          {
            firstName: data.firstNameTutor,
            lastName: data.lastNameTutor,
            mobilePhone: data.mobilePhoneTutor,
            email: data.emailTutor,
            documentNumber: Number(data.documentNumberTutor),
          },
          student.data.tutor.id
        );
      })
      .then(() => {
        // alert("Alumno editado en la base de datos");
        history.push('/admin/jugadores');
      })
      .catch((err) => {
        alert(err);
      });
  } else {
    return postStudent({
      documentNumber: Number(data.documentNumber),
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: data.birthDate,
      email: data.email,
      mobilePhone: data.mobilePhone,
      grade: data.grade,
      availabilities: data.availabilities,
      subjects: data.subjects,
      firstNameTutor: data.firstNameTutor,
      lastNameTutor: data.lastNameTutor,
      mobilePhoneTutor: data.mobilePhoneTutor,
      emailTutor: data.emailTutor,
      documentNumberTutor: Number(data.documentNumberTutor),
      relation: data.relation,
    })
      .then(({ status }) => {
        if (status >= 200) history.push('/admin/jugadores');
      })
      .catch((err) => {
        alert(err);
      });
  }
};

export const backToStudentList = (history) => {
  history.push('/entrenador/misjugadores');
};
