import React from 'react';
import {
  Select,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Box,
} from '@material-ui/core';
import useStyles from './style';

export default function SecondFrom({ data, setData, errors, disabled }) {
  const classes = useStyles();

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form className={classes.root}>
      <h3>Datos de madre, padre o tutor</h3>
      <TextField
        id="documentNumber-inputTutor"
        name="documentNumberTutor"
        label="Número de documento"
        value={data.documentNumberTutor}
        onChange={onChange}
        disabled={disabled}
        error={errors.documentNumberTutor}
        helperText={errors.documentNumberTutor}
      />
      <TextField
        id="firstName-inputTutor"
        name="firstNameTutor"
        label="Nombre"
        value={data?.firstNameTutor}
        onChange={onChange}
        disabled={disabled}
        error={errors.firstNameTutor}
        helperText={errors.firstNameTutor}
      />
      <TextField
        id="lastName-inputTutor"
        name="lastNameTutor"
        label="Apellido"
        value={data?.lastNameTutor}
        onChange={onChange}
        disabled={disabled}
        error={errors.lastNameTutor}
        helperText={errors.lastNameTutor}
      />
      <TextField
        id="mobilePhone-inputTutor"
        name="mobilePhoneTutor"
        label="Celular (sin el 0 ni el 15)"
        value={data?.mobilePhoneTutor}
        onChange={onChange}
        disabled={disabled}
        error={errors.mobilePhoneTutor}
        helperText={errors.mobilePhoneTutor}
      />
      <TextField
        id="email-inputTutor"
        name="emailTutor"
        label="Email"
        value={data?.emailTutor}
        onChange={onChange}
        disabled={disabled}
        error={errors.emailTutor}
        helperText={errors.emailTutor}
      />
      <Box>
        <FormControl style={{ width: '100%' }} disabled={disabled}>
          <InputLabel id="select-label">Tipo de relación</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={data.relation}
            name="relation"
            onChange={onChange}
            disabled={disabled}
          >
            <MenuItem value={'tutor'}>Tutor</MenuItem>
            <MenuItem value={'mother'}>Madre</MenuItem>
            <MenuItem value={'father'}>Padre</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </form>
  );
}
