import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  containerForms: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '3rem',
  },
  buttonAddStudent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default useStyles;
