import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';

import FeedbackModal from '../../../common/FeedbackModal';

import { getMe, getVolunteerFeedbacks } from '../../../../service/api';

const translate = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
};

const FeedbacksVolunteerTable = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackInfo, setFeedbackInfoModal] = useState({
    open: false,
    feedbackDetail: null,
  });

  useEffect(() => {
    getMe()
      .then(({ data: user }) => getVolunteerFeedbacks(user.volunteerId))
      .then(({ data: volunteerFeedbacks }) => {
        setFeedbacks(
          volunteerFeedbacks
            .map((volunteerFeedback) => {
              const feedbackDay = new Date(volunteerFeedback.date).getDay();
              return {
                ...volunteerFeedback,
                firstName: volunteerFeedback.lesson?.student?.user?.firstName,
                lastName: volunteerFeedback.lesson?.student?.user?.lastName,
                start: `${volunteerFeedback.lesson.start}:00hs`,
                end: `${volunteerFeedback.lesson.end}:00hs`,
                day: translate[volunteerFeedback.lesson.day]
                  ? translate[volunteerFeedback.lesson.day]
                  : volunteerFeedback.lesson.day,
                date: new Date(volunteerFeedback.date).toLocaleDateString(
                  'es-AR'
                ),
                lessonDay: translate[feedbackDay]
                  ? translate[feedbackDay]
                  : new Date(feedbackDay).toLocaleDateString('es-AR'),
              };
            })
            .sort((a, b) => b.id - a.id)
        );
      });
  }, []);

  const handleCloseFeedbackModal = () =>
    setFeedbackInfoModal({ open: false, feedbackDetail: null });

  return (
    <>
      <MaterialTable
        title="Mis Informes"
        columns={[
          { title: 'Nombre', field: 'firstName' },
          { title: 'Apellido', field: 'lastName' },
          { title: 'Día de las Clases', field: 'day' },
          { title: 'Día del Partido', field: 'lessonDay' },
          { title: 'Fecha del Partido', field: 'date' },
          { title: 'Hora de inicio', field: 'start' },
          { title: 'Hora de finalización', field: 'end' },
        ]}
        data={feedbacks}
        actions={[
          {
            icon: () => <VisibilityIcon color="primary" />,
            tooltip: 'Ver informe',
            onClick: (_event, rowData) =>
              setFeedbackInfoModal({
                open: true,
                feedbackDetail: feedbacks.find(
                  (feedback) => feedback.id === rowData.id
                ),
              }),
          },
        ]}
        options={{
          exportButton: true,
          searchFieldVariant: 'outlined',
          actionsColumnIndex: -1,
          rowStyle: { fontFamily: 'poppins' },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsPerPage: 'Filas por página',
            lastTooltip: 'Última página',
            firstTooltip: 'Primera página',
            nextTooltip: 'Página siguiente',
            previousTooltip: 'Página anterior',
            labelRowsSelect: 'Filas',
          },
          body: {
            emptyDataSourceMessage: 'No se han encontrado informes',
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
          toolbar: {
            searchPlaceholder: 'Buscar informes',
          },
          header: {
            actions: 'Informe ',
          },
        }}
      />
      <FeedbackModal
        feedbackInfo={feedbackInfo}
        onClose={handleCloseFeedbackModal}
      />
    </>
  );
};
export default FeedbacksVolunteerTable;
