import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  contact: {
    boxSizing: 'border-box',
    position: 'absolute',
    display: 'inline',
    width: '60%',
    color: 'white',
    height: '35%',
    top: '3rem',
    left: '3rem',
  },
  redes: {
    margin: '1rem',
    alignItems: 'flex-end',
  },
  emailContainer: {
    padding: '1rem',
    boxSizing: 'border-box',
    borderRadius: '10px',
    color: 'white',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '50%',
    top: '17rem',
    backgroundColor: 'rgba(255,255,255,0.8)',
    left: '3rem',
  },
  email: {
    color: 'white',
    width: '80%',
    margin: '1rem',
  },
  msj: {
    color: 'white',
    width: '95%',
    margin: '1rem',
  },
  btn: {
    width: '30%',
    alignSelf: 'center',
    margin: '1em',
  },
  title: {
    marginLeft: '1rem',
    fontSize: '70px',
  },
  subtitle: {
    position: 'absolute',
    fontSize: '17px',
    margin: '1%',
    fontFamily: 'Poppins',
  },
  card: {
    backgroundColor: '#FAF9F4',
    borderRadius: '1em',
    width: '96%',
  },
  container: {
    margin: '2em',
  },
  instagram: {
    borderRadius: '2em',
    fontFamily: 'Poppins',
  },
  img: {
    height: '20%',
    width: '98%',
  },
});

export default useStyles;
