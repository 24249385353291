import React, { useState } from 'react';
import useStyles from './style';
import { TextField, FormControl, Grid, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import img from '../../../../static/images/Home.jpg';
import Header from '../../Form/Header/Header';
import { resetPassword } from '../../../../service/api';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
export default function Password() {
  const history = useHistory();
  const [email, setEmail] = useState({
    email: '',
  });
  const onChange = (e) => {
    setEmail(() => ({
      email: e.target.value,
    }));
  };

  const handleClick = async () => {
    if (email.email === '') {
      swal({
        title: `Error`,
        text: 'ingresá tu email',
        icon: 'warning',
      });
    } else {
      resetPassword(email);
      swal({
        title: `Revisá tu casilla`,
        text: 'Te enviamos un email donde podras resetear tu contraseña',
        icon: 'info',
      }).then((e) => {
        history.push(`/logIn`);
      });
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={img} className={classes.img} alt="" />
      <Header></Header>
      <Box boxShadow={3} className={classes.box_container} p={3}>
        <Typography className={classes.tipography}>
          Ingresá el email que usaste para registrarte. Te enviaremos un mensaje
          con un enlace para restablecer tu contraseña.
        </Typography>
        <Typography style={{ fontWeight: 'bold' }}>
          Si no encontrás el email, revisá tu casilla de Spam
        </Typography>
        <FormControl className={classes.gral}>
          <Grid item>
            <TextField
              onChange={onChange}
              name="email"
              id="standard-basic"
              label="Ingresá tu email"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Enviar email
            </Button>
          </Grid>
        </FormControl>
      </Box>
    </div>
  );
}
