import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  root: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default useStyles;
