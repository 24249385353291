import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './style';
import { getSubjects } from '../../../service/api';
import GradeSelect from './GradeSelect/GradeSelect';
import SubjectsButtons from './SubjectsButtons/SubjectsButtons';
import SubjectsDetail from './SubjectsDetail/SubjectsDetail';

export default function FourthForm({
  student,
  volunteer,
  data,
  setData,
  errors,
  disabled,
}) {
  const [subjects, setSubjects] = useState({});
  const [level, setLevel] = React.useState('Primaria');

  useEffect(() => {
    getSubjects().then((response) => {
      setSubjects(response.data);
    });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      container
      className={
        student ? classes.containerStudentForm : classes.containerVolunteerForm
      }
    >
      <Grid item>
        <GradeSelect level={level} setLevel={setLevel} disabled={disabled} />
      </Grid>
      <Grid item>
        <SubjectsButtons
          data={data}
          setData={setData}
          subjects={subjects}
          level={level}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <SubjectsDetail data={data} subjects={subjects} errors={errors} />
      </Grid>
    </Grid>
  );
}
