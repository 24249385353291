import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backgroundPictureContainer: {
    display: 'flex',
    width: '70%',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  backgroundPicture: {
    position: 'absolute',
    width: '72%',
    height: '100%',
  },

  bicycleImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  png: {
    position: 'absolute',
    'z-index': 5,
    'margin-top': '10%',
    width: '40%',
    height: 'auto',
    left: '55%',
    animation: '$mov',
    'animation-duration': '3s',
    'animation-timing-function': 'ease-in-out',
  },

  sumate: {
    color: 'white',
    textShadow: '2px 2px 2px #000',
    position: 'relative',
    width: '100%',
    height: 'fit-content',
    left: '-15%',
    bottom: '-65%',
    animation: '$titleAni',
    'animation-duration': '2s',
    'animation-timing-function': 'ease-in-out',
  },

  innerSumate: {
    width: '50%',
  },

  sumateText: {
    fontFamily: 'Poppins, sans serif',
    textShadow: '2px 2px 2px #000',
  },
  sumateTitle: {
    fontFamily: 'Poppins, sans serif',
    fontWeight: 600,
    fontSize: '3.8rem',
  },

  formContainer: {},

  '@keyframes mov': {
    '0%': {
      left: '-50%',
    },
    '100%': {
      left: '55%',
    },
  },

  '@keyframes titleAni': {
    '0%': {
      color: 'transparent',
    },
    '100%': {
      color: 'white',
    },
  },
}));

export default useStyles;
