import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import VolunteerProfile from './PersonalData/VolunteerProfile';
import CircularSpinner from '../../../../Spinners/CircularSpinner';
import { getVolunteerById } from '../../../../../service/api';

const VolunteerContainer = () => {
  const { id } = useParams<any>();

  const [VoluntDataPersonal, setVoluntDataPersonal] = React.useState({});
  const [VoluntDataSubjects, setVoluntDataSubjects] = React.useState({});
  const [load, setLoad] = React.useState(false);
  const [error, setError] = React.useState('');

  const getVolunteerData = () => {
    getVolunteerById(id)
      .then(({ data }) => {
        setVoluntDataPersonal({
          email: data.user?.email,
          firstName: data.user?.firstName,
          lastName: data.user?.lastName,
          documentNumber: data.user?.documentNumber,
          curriculum: data.curriculum,
          documentBack: data.documentBack,
          documentFront: data.documentFront,
          linkedIn: data.linkedIn,
          status: data.status,
        });
        setVoluntDataSubjects({
          subjects: data.subjects,
          availabilities: data.user?.availabilities,
        });
      })
      .then(() => setLoad(true))
      .catch((error) => setError(error));
  };

  React.useEffect(() => {
    getVolunteerData();
  }, []);

  return load ? (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <VolunteerProfile
          VoluntDataPersonal={VoluntDataPersonal}
          error={error}
          VoluntDataSubjects={VoluntDataSubjects}
        />
      </Grid>
    </Grid>
  ) : (
    <CircularSpinner />
  );
};

export default VolunteerContainer;
