import React from 'react';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';

const CustomButton = ({ children }) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  return (
    <Button
      onClick={handleSubmit}
      variant="contained"
      color="primary"
      fullWidth
    >
      {children}
    </Button>
  );
};

export default CustomButton;
