import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Poppins',
  },
}));

export default useStyles;
