import React from 'react';
import { Formik, Form } from 'formik';
import { Grid } from '@material-ui/core';

import CustomTextField from '../UI/CustomTextField';
import CustomButton from '../UI/CustomButton';

import validationSchema from './validate';

const UserInfo = ({ setData, setStep }) => {
  const initialValues = {
    firstName: '',
    lastName: '',
    birthDate: '',
    mobilePhone: '',
    email: '',
    institute: '',
  };

  return (
    <Grid container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setData((data) => ({ ...data, ...values }));
          setStep((step) => step + 1);
        }}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField name="firstName" label="Nombre" />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField name="lastName" label="Apellido" />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField name="email" label="Email" />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField name="mobilePhone" label="Teléfono" />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField name="institute" label="Institución" />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="birthDate"
                label="Fecha de Nacimiento"
                inputProps={{
                  max: '2002-01-01',
                }}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton>Siguiente</CustomButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
};

export default UserInfo;
