import React from 'react';
import { Box } from '@material-ui/core';
import { Route } from 'react-router-dom';
import VolunteersList from '../../../ViewOfAdmin/Volunteers/VolunteersList/VolunteersList';
import useStyles from './Style';
import ViewAdmin from '../../../ViewOfAdmin/AdminView/ViewAdmin';
import VolunteerContainer from '../../../ViewOfAdmin/Volunteers/VolunteerProfile/VolunteerContainer';
import StudentsList from '../../../../StudentsInformation/StudentsList/StudentsList';
import StudentCRUD from '../../../../StudentsInformation/StudentCRUD/StudentCRUD';
import Calendar from '../../../Calendar/Calendar';
import StudentFeedbacks from '../../../ViewOfAdmin/StudentFeedbacks/StudentFeedbacks';
import TeamAssignment from '../../../ViewOfAdmin/TeamAssignment/TeamAssignment';

const RoutesComponents = () => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <Box className={classes.toolbar} />
      <Route exact path="/admin" component={ViewAdmin} />
      <Route exact path="/admin/entrenadores" component={VolunteersList} />
      <Route
        exact
        path="/admin/entrenador/:id"
        component={VolunteerContainer}
      />
      <Route
        exact
        path="/admin/jugadores"
        render={() => <StudentsList volunteer={false} />}
      />
      <Route
        exact
        path="/admin/nuevo-jugador"
        render={() => <StudentCRUD volunteer={false} />}
      />
      <Route
        exact
        path="/admin/jugador/:id"
        render={() => <StudentCRUD volunteer={false} />}
      />
      <Route exact path="/admin/calendario" component={Calendar} />
      <Route exact path="/admin/asignarEquipo" component={TeamAssignment} />
      <Route
        exact
        path="/admin/jugadores/informes/:studentId"
        component={StudentFeedbacks}
      />
    </main>
  );
};

export default RoutesComponents;
