import { makeStyles } from '@material-ui/core';
import img from '../../static/images/Home.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100%',
    justifyContent: 'space-around',
  },
  logoImg: {
    width: '12rem',
    height: '12rem',
    [theme.breakpoints.down('sm')]: {
      width: '8rem',
      height: '8rem',
    },
  },
  buttonsContainer: {
    width: '80%',
    justifyContent: 'space-around',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  container: {
    margin: '1rem',
    textAlign: 'center',
    padding: '3rem',
    cursor: 'pointer',
    borderRadius: '2rem',
    border: '.3rem solid white',
    '&:hover': {
      transform: 'scale(1.05)',
      transition: '0.2s',
    },
    '&:not(:hover)': {
      transition: '0.2s',
      transform: 'scale(1)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
  },
  big: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'white',
  },
  typography: {
    fontFamily: 'downward',
  },
}));

export default useStyles;
