import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  overflow: {
    'overflow-x': 'hidden',
  },
  table: {
    minWidth: 650,
  },
  cell: {
    background: 'rgb(129,199,132)',
    'z-index': 2,
    position: 'relative',
    height: '30px',
  },
  start: {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    marginLeft: '3px',
  },
  end: {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    marginRight: '3px',
  },
});

export default useStyles;
