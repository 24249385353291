import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CreateClassButton from './Classes/CreateClassButton/CreateClassButton';

const HomeVolunteer = () => {
  return (
    <Grid
      container
      direction="column"
      style={{
        height: '70vh',
        justifyContent: 'space-evenly',
        textAlign: 'center',
      }}
    >
      <Grid item>
        <Typography
          variant="h3"
          gutterBottom
          style={{ fontFamily: 'Downward' }}
        >
          Bienvenido al panel de Entrenador
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins' }}>
          ¡Acá tendrás la información de tu jugador y clases, y podrás cargar
          los datos necesarios!
        </Typography>
        <Typography variant="h6" style={{ fontFamily: '  poppins' }}>
          Podés navegar por los íconos de tu panel izquierdo.
        </Typography>
      </Grid>
      <Grid item>
        <CreateClassButton />
      </Grid>
    </Grid>
  );
};

export default HomeVolunteer;
