import { GET_USER, LOGIN, LOGOUT, SESSION_LOADING } from './constants';

export function sessionLogin(user) {
  return {
    type: LOGIN,
    payload: user,
  };
}

export function sessionLogout() {
  return {
    type: LOGOUT,
  };
}

export function sessionLoading(isLoading: boolean) {
  return {
    type: SESSION_LOADING,
    payload: isLoading,
  };
}

export function getUser(user) {
  return {
    type: GET_USER,
    payload: user,
  };
}
