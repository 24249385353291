import { useLocation } from 'react-router';
export default function useQuery() {
  let search = useLocation().search;
  let result: any = search.slice(1).split('&');
  result = result.reduce((dataResult, item) => {
    const pair = item.split('=');
    dataResult[pair[0]] = pair[1];
    return dataResult;
  }, {});
  return result;
}
