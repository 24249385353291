import React from 'react';
import { Box, Typography } from '@material-ui/core';
const status_sp = {
  accepted: 'Aceptado',
  disabled: 'Receso',
};
export default function PersonalData({ VoluntDataPersonal }) {
  return (
    <Box>
      <Typography gutterBottom variant="h5" component="h2">
        Datos personales:
      </Typography>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          Email: {VoluntDataPersonal.email}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          Apellido: {VoluntDataPersonal.lastName}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          Nombre: {VoluntDataPersonal.firstName}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          Estado: {status_sp[VoluntDataPersonal.status]}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          N° de Documento: {VoluntDataPersonal.documentNumber}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          gutterBottom
        >
          LinkedIn:{' '}
          {VoluntDataPersonal.linkedIn ? VoluntDataPersonal.linkedIn : 'Vacio'}
        </Typography>
      </Box>
    </Box>
  );
}
