import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import FeedbacksVolunteerTable from './FeedbacksTable';

const FeedbacksVolunteer = () => {
  return (
    <Grid spacing={3} style={{ textAlign: 'center' }}>
      <Grid item>
        <Typography
          variant="h3"
          gutterBottom
          style={{ fontFamily: '  Downward' }}
        >
          Informes
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontFamily: '  poppins' }}
        >
          En esta sección podrás ver y modificar los informes que has
          completado.
        </Typography>
      </Grid>
      <Grid item>
        <FeedbacksVolunteerTable />
      </Grid>
    </Grid>
  );
};

export default FeedbacksVolunteer;
