import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import useStyles from './style';

const steps = [1, 2, 3, 4];

export default function StepperForm({ step }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((key) => (
          <Step key={key}>
            <StepLabel />
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
