import a from 'axios';
import swal from 'sweetalert';
/* import { registerBody } from "./interfaces"; */
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const axios = a.create({ withCredentials: true });

const errorHandler = (error) => {
  if (error.response?.data?.errors[0]?.message) {
    let messages = `Status: ${error.response.status}\n`;
    error.response.data.errors.forEach((err) => {
      messages += `${err.message}\n`;
    });
    swal({
      title: `Error`,
      text: `${messages}`,
      icon: 'info',
    });
  } else {
    swal({
      title: `Error`,
      text: `${error}`,
      icon: 'info',
    });
  }
  return error;
};

// Users:
export const putPassword = (body): Promise<any> =>
  axios.put(`${BACKEND_URL}/api/v1/users/password`, body).catch(errorHandler);

export const resetPassword = (body): Promise<any> =>
  axios
    .post(`${BACKEND_URL}/api/v1/users/resetPassword`, body)
    .catch(errorHandler);

export const postLogin = (body): Promise<any> =>
  axios.post(`${BACKEND_URL}/api/v1/users/login`, body).catch(errorHandler);

export const getMe = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/users/me`).catch(errorHandler);

export const getLogout = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/users/logout`).catch(errorHandler);

// Volunteers:
export const postRegister = (body): Promise<any> =>
  axios
    .post(`${BACKEND_URL}/api/v1/spec/volunteers/register`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .catch(errorHandler);

export const updateVolunteer = (id, body): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/spec/volunteers/availabilities/${id}`, body)
    .catch(errorHandler);

export const getVolunteers = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/spec/volunteers/list`).catch(errorHandler);

export const addLinkage = (volunteerId, studentId): Promise<any> =>
  axios
    .post(`${BACKEND_URL}/api/v1/spec/volunteers/${volunteerId}/${studentId}`)
    .catch(errorHandler);

export const getVolunteerById = (id): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/spec/volunteers/${id}`).catch(errorHandler);

export const aceptedVolunteer = (id): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/spec/volunteers/accepted/${id}`)
    .catch(errorHandler);

export const rejectVolunteer = (id): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/spec/volunteers/rejected/${id}`)
    .catch(errorHandler);

export const disableVolunteer = (id): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/spec/volunteers/disabled/${id}`)
    .catch(errorHandler);

export const reincorporateVolunteer = (id): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/spec/volunteers/reincorporate/${id}`)
    .catch(errorHandler);

export const deleteVolunteer = (id): Promise<any> =>
  axios
    .delete(`${BACKEND_URL}/api/v1/spec/volunteers/${id}`)
    .catch(errorHandler);

export const reProcessVolunteer = (id): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/spec/volunteers/process/${id}`)
    .catch(errorHandler);

export const myStudents = (id): Promise<any> =>
  axios
    .get(`${BACKEND_URL}/api/v1/spec/volunteers/students/${id}`)
    .catch(errorHandler);

// Students:
export const postStudent = (body): Promise<any> =>
  axios
    .post(`${BACKEND_URL}/api/v1/students/register`, body)
    .catch(errorHandler);

export const modifyStudent = (body, id): Promise<any> =>
  axios.put(`${BACKEND_URL}/api/v1/students/${id}`, body).catch(errorHandler);

export const changeStudentStatus = (id, status): Promise<any> =>
  axios
    .put(`${BACKEND_URL}/api/v1/students/${id}/status/${status}`)
    .catch(errorHandler);

export const deleteStudentById = (id): Promise<any> =>
  axios.delete(`${BACKEND_URL}/api/v1/students/${id}`).catch(errorHandler);

export const getStudents = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/students/list`).catch(errorHandler);

export const getStudentsByAvailability = (volunteerId: number): Promise<any> =>
  axios
    .get(
      `${BACKEND_URL}/api/v1/students/compatibility${
        volunteerId ? `?volunteerId=${volunteerId}` : ''
      }`
    )
    .catch(errorHandler);

export const getVolunteersByAvailability = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/spec/volunteers`).catch(errorHandler);

export const getStudentById = (id): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/students/${id}`).catch(errorHandler);

// Tutors:
export const getTutorById = (id): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/tutors/${id}`).catch(errorHandler);

export const modifyTutor = (body, id): Promise<any> =>
  axios.put(`${BACKEND_URL}/api/v1/tutors/${id}`, body).catch(errorHandler);

// Subjects:
export const getSubjects = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/subjects`).catch(errorHandler);

export const getSubjectsPrimarySchool = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/subjects/primarySchool`).catch(errorHandler);

export const getSubjectsSecondarySchool = (): Promise<any> =>
  axios
    .get(`${BACKEND_URL}/api/v1/subjects/secondarySchool`)
    .catch(errorHandler);

// Calendar:
export const getCalendar = (): Promise<any> =>
  axios.get(`${BACKEND_URL}/api/v1/calendar`).catch(errorHandler);

// Admin
export const sendEmailAdmin = (body): Promise<any> =>
  axios.post(`${BACKEND_URL}/api/v1/spec/admin`, body).catch(errorHandler);

// Lessons
export const postlesson = (body): Promise<any> =>
  axios.post(`${BACKEND_URL}/api/v1/lessons`, body).catch(errorHandler);

export const getLessons = (volunteerId): Promise<any> =>
  axios
    .get(`${BACKEND_URL}/api/v1/lessons/volunteers/${volunteerId}`)
    .catch(errorHandler);

// Feedbacks
export const postFeedback = (body): Promise<any> =>
  axios.post(`${BACKEND_URL}/api/v1/feedbacks`, body).catch(errorHandler);

export const getVolunteerFeedbacks = (volunteerId): Promise<any> =>
  axios
    .get(`${BACKEND_URL}/api/v1/feedbacks/${volunteerId}`)
    .catch(errorHandler);

export const getStudentFeedbacks = (studentId): Promise<any> =>
  axios
    .get(`${BACKEND_URL}/api/v1/feedbacks/student/${studentId}`)
    .catch(errorHandler);

export const deleteTeam = (volundeerId, studentId): Promise<any> =>
  axios
    .put(
      `${BACKEND_URL}/api/v1/spec/volunteers/removeRelation/${volundeerId}/${studentId}`
    )
    .catch(errorHandler);
