import React, { useEffect, useState } from 'react';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
} from 'formik-material-ui-pickers';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Swal from 'sweetalert2';
import { es } from 'date-fns/locale';
import {
  Button,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Grid,
} from '@material-ui/core';
import { AddSharp } from '@material-ui/icons';
import useStyles from './styles';
import { postlesson, getMe, myStudents } from '../../../../../service/api';
import validationSchema from './validate';

export default function CreateClassButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [students, setStudents] = useState([]);
  const [volunteerId, setVolunteer] = useState('');

  useEffect(() => {
    getMe()
      .then(({ data: user }) =>
        Promise.all([user, myStudents(user.volunteerId)])
      )
      .then(([user, { data: students }]) => {
        setVolunteer(user.volunteerId);
        setStudents(students);
      });
  }, []);

  const onSubmit = (values) => {
    postlesson({
      ...values,
      volunteerId,
      startDate: values.startDate,
      endDate: values.endDate,
      start: values.start.getHours(),
      end: values.end.getHours(),
    }).then(({ status }) => {
      setOpen(false);
      if (status === 201) {
        //TODO: Prevent reloads, use better state handlers instead(redux)
        Swal.fire(
          'Clase creada con éxito',
          'Recuerda completar semanalmente los informes de la misma, ¡Mucha suerte!',
          'success'
        ).then(() => window.location.reload());
      }
    });
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => setOpen(true)}
        startIcon={<AddSharp />}
      >
        Crear Clase
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Programar clase</DialogTitle>
        <DialogContent className={classes.root}>
          <DialogContentText>
            Completa los datos a continuación para programar una clase
            <br></br>
            Todos los datos son obligatorios.
          </DialogContentText>

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <Grid container>
              <Formik
                initialValues={{
                  volunteerId,
                  studentId: students?.length ? students[0].id : '',
                  day: '',
                  start: new Date(new Date().setHours(7)),
                  end: new Date(new Date().setHours(8)),
                  startDate: new Date(new Date().setHours(0)),
                  endDate: new Date(new Date().setHours(0)),
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <Field
                          fullWidth
                          id="studentId"
                          component={TextField}
                          label="Estudiante"
                          type="text"
                          name="studentId"
                          select={true}
                          SelectProps={{
                            multiple: false,
                          }}
                        >
                          {students.map((student) => (
                            <MenuItem key={student.id} value={student.id}>
                              {student.user?.firstName} {student.user?.lastName}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>

                      <Grid item xs={9}>
                        <Field
                          id="day"
                          fullWidth
                          component={TextField}
                          name="day"
                          label="Día de la clase"
                          select={true}
                          SelectProps={{
                            multiple: false,
                          }}
                        >
                          <MenuItem value="monday">Lunes</MenuItem>
                          <MenuItem value="tuesday">Martes</MenuItem>
                          <MenuItem value="wednesday">Miércoles</MenuItem>
                          <MenuItem value="thursday">Jueves</MenuItem>
                          <MenuItem value="friday">Viernes</MenuItem>
                        </Field>
                        <Field
                          id="start"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="start"
                          format="HH:00"
                          ampm={false}
                          component={KeyboardTimePicker}
                          label="Hora de Comienzo"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Field
                          fullWidth
                          id="end"
                          name="end"
                          format="HH:00"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          component={KeyboardTimePicker}
                          ampm={false}
                          label="Hora de Finalización"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Field
                          fullWidth
                          id="startDate"
                          name="startDate"
                          format="dd/MM/yyyy"
                          component={KeyboardDatePicker}
                          label="Fecha de Inicio"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Field
                          fullWidth
                          id="endDate"
                          name="endDate"
                          format="dd/MM/yyyy"
                          component={KeyboardDatePicker}
                          label="Fecha de Finalización"
                        />
                      </Grid>
                      {isSubmitting && <LinearProgress color="primary" />}
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          onClick={() => setOpen(false)}
                          color="primary"
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          onClick={submitForm}
                          variant="contained"
                          disabled={isSubmitting || !volunteerId}
                          color="primary"
                        >
                          Programar
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
