import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  img: {
    position: 'fixed',
    'z-index': '0',
    width: '100%',
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  subForm: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  gral: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    height: '50%',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  date: {
    width: '300px',
  },
  box_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '30%',
    height: '50%',
    borderRadius: '30px',
    'z-index': 1,
    backgroundColor: 'white',
    opacity: '0.9',
  },
  tipography: {
    fontFamily: 'Poppins, sans serif',
  },
  pass: {
    marginLeft: '15px',
    marginTop: '15px',
  },
});
export default useStyles;
