import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './Style';
import { getLogout } from '../../../../../service/api';
import swal from 'sweetalert';
// Interface types
interface NavbarProps {
  showHide: () => void;
  state: boolean;
}
//Component Navbar
export const Navbar = ({ showHide, state }: NavbarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const logout = () => {
    swal({
      title: `¿Estás seguro que querés cerrar sesión?`,
      text: 'Te redireccionaremos al inicio',
      icon: 'info',
      buttons: ['Cancelar', 'Aceptar'],
    }).then((rta) => {
      if (rta) {
        getLogout().then(() => {
          history.push('/');
        });
      }
    });
  };
  return (
    <Box className={classes.root}>
      {/* Navigation */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: state,
        })}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={showHide}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: state,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Dashboard Admin
            </Typography>
          </div>
          <Button className={classes.logout} color="inherit" onClick={logout}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '100%',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                className="bi bi-door-open-fill"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
              </svg>
              Cerrar sesión
            </div>
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
