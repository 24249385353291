import Typography from '@material-ui/core/Typography';
export default function Description() {
  return (
    <div className="text">
      <Typography
        style={{ fontFamily: 'Downward', fontSize: '340%', color: '#DAD8CF' }}
      >
        Contacto
      </Typography>
      <br />
      <Typography variant="subtitle1">
        En este espacio encontrarás todas nuestras redes. Además, si tenés
        alguna duda con respecto al formulario de inscripción o tenés algo para
        decirnos, escribí acá y te respondemos.
      </Typography>
    </div>
  );
}
