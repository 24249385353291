import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  button: {
    borderRadius: '99px',
    lineHeight: '0.8',
    fontSize: '12px',
    borderColor: '#DEDCD8',
    color: '#6A6A6A',
  },
  label: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '12px',
  },
}));

export default useStyles;
