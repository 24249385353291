import React from 'react';
import {
  Card,
  Box,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Divider,
} from '@material-ui/core';
import SubjectsClass from '../InfoOfClass/SubjectsClass';
import TimeClass from '../InfoOfClass/TimeClass';
import DataPersonal from './DataPersonal';

const VolunteerProfile = ({
  VoluntDataPersonal,
  VoluntDataSubjects,
  error,
}: {
  VoluntDataPersonal: any;
  VoluntDataSubjects: any;
  error?: any;
}) => {
  return (
    <Box style={{ padding: '1rem' }}>
      <Card style={{ height: '100%' }}>
        <Grid container direction="column">
          <Grid container xs={12} alignItems="center">
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">{`${VoluntDataPersonal.firstName.slice(
                  0,
                  1
                )}${VoluntDataPersonal.lastName.slice(0, 1)}`}</Avatar>
              }
              title={`${VoluntDataPersonal.lastName} ${VoluntDataPersonal.firstName}`}
            />
          </Grid>
          <Divider />
          <Grid container direction="row" justify="center">
            <Grid xs={4} container justify="center">
              <CardContent>
                <DataPersonal VoluntDataPersonal={VoluntDataPersonal} />
              </CardContent>
            </Grid>
            <Grid item xs={4} container justify="center">
              <CardContent>
                <SubjectsClass VoluntDataSubjects={VoluntDataSubjects} />
              </CardContent>
            </Grid>
            <Grid item xs={4} container justify="center">
              <CardContent>
                <TimeClass VoluntDataSubjects={VoluntDataSubjects} />
              </CardContent>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default VolunteerProfile;
