import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { week } from '../../../../../../utils/week';

export const translate = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
};
const useStyles = makeStyles({
  bottomNavigation: {
    minWidth: '.1em',
    color: '#3f51b5',
  },
});

export default function WeekButtons({ day, setDay }) {
  const classes = useStyles();
  const onChange = (e, newDay) => {
    setDay(newDay);
  };

  return (
    <BottomNavigation value={day} onChange={onChange} showLabels>
      {week.map((d, i) => (
        <BottomNavigationAction
          component="div"
          value={d}
          label={translate[d]}
          key={i}
          className={classes.bottomNavigation}
        />
      ))}
    </BottomNavigation>
  );
}
