import React from 'react';
import { Box, Fade } from '@material-ui/core';
import { Route } from 'react-router-dom';
import useStyles from './styles';
import ViewVolunteer from '../../../Sections/HomeVolunteer';
import ClassVolunteer from '../../../Sections/Classes/ClassesTable';
import StudentsList from '../../../../StudentsInformation/StudentsList/StudentsList';
import StudentCRUD from '../../../../StudentsInformation/StudentCRUD/StudentCRUD';
import LessonEvaluation from '../../../Sections/LessonEvaluation/LessonEvaluation';
import VolunteerFeedbacks from '../../../Sections/Feedbacks/Feedbacks';
import UserProfile from '../../../Sections/VolunteerProfile/VolunteerProfile';

const PageShell = (Page) => {
  return (props) => (
    <div className="page">
      <Fade in={true}>
        <Box>
          <Page {...props} />
        </Box>
      </Fade>
    </div>
  );
};

const RoutesComponents = () => {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <Box className={classes.toolbar} />
      <Route exact path="/entrenador" component={PageShell(ViewVolunteer)} />
      <Route
        exact
        path="/entrenador/clases"
        component={PageShell(ClassVolunteer)}
      />
      <Route
        exact
        path="/entrenador/reportes"
        component={PageShell(VolunteerFeedbacks)}
      />
      <Route
        path="/entrenador/clases/feedback/:lessonId"
        component={PageShell(LessonEvaluation)}
      />
      <Route
        exact
        path="/entrenador/misjugadores"
        render={() => <StudentsList volunteer={true} />}
      />
      <Route
        exact
        path="/entrenador/jugador/:id"
        render={() => <StudentCRUD volunteer={true} />}
      />
      <Route
        exact
        path="/entrenador/perfil"
        component={PageShell(UserProfile)}
      />
    </main>
  );
};

export default RoutesComponents;
