import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 16,

    display: 'flex',
    flexDirection: 'column',
    'z-index': 1,
  },
  image: {
    width: 250,
    height: 95,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    animation: '$rotation',
    'animation-duration': '45s',
    'animation-iteration-count': 'infinite',
    'animation-timing-function': 'ease-in-out',
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '10%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(360deg)',
    },
    '90%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  tipography: {
    fontFamily: 'Poppins, sans serif',
  },
  responsive: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyle;
