import React, { useState, useEffect } from 'react';
import { Container, Fade } from '@material-ui/core';

import { week } from '../../../utils/week';
import Sliders from './Sliders/Sliders';
import WeekButtons from './WeekButtons/WeekButtons';
import Responsive from './responsive/responsive';
import { useStyles } from './style';
import ResponsiveDetail from './responsive/responsiveDetail/responsiveDetail';

export default function ThirdForm({
  student,
  volunteer,
  data,
  setData,
  errors,
  disabled,
}) {
  const [day, setDay] = useState(week[0]);
  const [transition, setTransition] = useState(true);

  useEffect(() => {
    setTransition(false);
    setTimeout(function () {
      setTransition(true);
    }, 100);
  }, [day]);

  const onTimeRangesChange = (timeRanges) => {
    setData((data) => ({
      ...data,
      availabilities: {
        ...data.availabilities,
        [day]: timeRanges,
      },
    }));
  };

  const classes = useStyles();

  return (
    <div style={{ width: '100%' }}>
      {/* responsive */}

      <div className={classes.responsive}>
        <WeekButtons day={day} setDay={setDay} />
        <Responsive
          onChange={onTimeRangesChange}
          day={day}
          value2={data.availabilities[day]}
        />
        <ResponsiveDetail
          data={data}
          day={day}
          setDay={setDay}
          errors={errors}
        />
      </div>
      <Container
        className={classes.root}
        style={student ? { width: '85%', marginBottom: '3rem' } : null}
      >
        {student ? <h3>Disponibilidad horaria</h3> : null}
        <WeekButtons day={day} setDay={setDay} />
        <Fade
          in={transition}
          style={{ transitionDelay: transition ? '100ms' : '0ms' }}
        >
          <Container>
            <Sliders
              day={day}
              value={data.availabilities[day]}
              onChange={onTimeRangesChange}
              disabled={disabled}
            />
            <div className={classes.detail}>
              <ResponsiveDetail
                data={data}
                day={day}
                setDay={setDay}
                errors={errors}
              />
            </div>
          </Container>
        </Fade>
      </Container>
    </div>
  );
}
