import React from 'react';
import Close from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import CheckIcon from '@material-ui/icons/Check';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

const FeedbackModal = ({ feedbackInfo, onClose }) => {
  return (
    <Dialog
      aria-labelledby="feedback-info-title"
      open={feedbackInfo.open}
      onClose={onClose}
    >
      <DialogTitle id="feedback-dialog-title">Detalle de Informe</DialogTitle>
      <DialogContent>
        <Grid container spacing={5} style={{ textAlign: 'center' }}>
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <Typography>
                <b>Estudiante:</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {feedbackInfo?.feedbackDetail?.lesson?.student?.user?.firstName}{' '}
                {feedbackInfo?.feedbackDetail?.lesson?.student?.user?.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <Typography>
                <b>Entrenador:</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {
                  feedbackInfo?.feedbackDetail?.lesson?.volunteer?.user
                    ?.firstName
                }{' '}
                {
                  feedbackInfo?.feedbackDetail?.lesson?.volunteer?.user
                    ?.lastName
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} item direction="row">
            <Grid item xs={1}>
              {feedbackInfo?.feedbackDetail?.assisted === 'yes' ? (
                <CheckIcon color="primary" />
              ) : (
                <Close color="error" />
              )}
            </Grid>
            <Grid container spacing={1} item xs={11}>
              <Grid item>
                <Typography>
                  <b>Asistencia:</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {`${
                    feedbackInfo?.feedbackDetail?.assisted === 'yes'
                      ? 'Sí, asistió'
                      : feedbackInfo?.feedbackDetail?.assisted === 'justified'
                      ? 'Inasistencia justificada'
                      : 'Inasistencia injustificada'
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} item direction="row">
            <Grid item xs={1}>
              {feedbackInfo?.feedbackDetail?.accompanying ? (
                <CheckIcon color="primary" />
              ) : (
                <Close color="error" />
              )}
            </Grid>
            <Grid container spacing={1} item xs={11}>
              <Grid item>
                <Typography>
                  <b>Acompañamiento: </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {`${
                    feedbackInfo?.feedbackDetail?.accompanying
                      ? `Sí: ${
                          feedbackInfo?.feedbackDetail?.whoAccompanied ||
                          'Acompañante no definido'
                        }`
                      : 'No tuvo compañía'
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={12}>
            <Grid item>
              <Typography>
                <b>Fecha de la clase: </b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{feedbackInfo?.feedbackDetail?.date}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>Desempeño</Typography>
            <Rating
              name="performance"
              value={Number(feedbackInfo?.feedbackDetail?.performance)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Concentración</Typography>
            <Rating
              name="concentration"
              value={Number(feedbackInfo?.feedbackDetail?.concentration)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Actitud</Typography>
            <Rating
              name="attitude"
              value={Number(feedbackInfo?.feedbackDetail?.attitude)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Conexión a internet</Typography>
            <Rating
              name="connection"
              value={Number(feedbackInfo?.feedbackDetail?.internetConnection)}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Typography>
                <b>Comentarios de la clase:</b>
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <Typography>
                {feedbackInfo?.feedbackDetail?.comments || 'Sin comentarios'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackModal;
