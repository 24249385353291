import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { Drawer, Divider, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useStyles from './styles';
import { Home, Classes, Players, Feedbacks, Profile } from './MenuLinks/Menu';

// Interface types
interface DrawerProps {
  showHide: () => void;
  state: boolean;
}

// Compenent DrawerLeft
const DrawerLeft = ({ showHide, state }: DrawerProps) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    // Drawer Component
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: state,
        [classes.drawerClose]: !state,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: state,
          [classes.drawerClose]: !state,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={showHide}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      {/* Menu Buttoms Icons Redirect Components */}
      <Home />
      <Profile />
      <Players />
      <Classes />
      <Feedbacks />
    </Drawer>
  );
};

export default DrawerLeft;
