import React from 'react';
import InputFile from './InputFile/InputFile';
import { TextField, Grid } from '@material-ui/core';
import useStyles from './style';
import Responsive from './responsive/responsive';

export default function SecondFrom({ data, setData, errors }) {
  const classes = useStyles();

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid className={classes.root}>
      <Grid container spacing={2} className={classes.form}>
        <Grid item xs={12}>
          <TextField
            value={data.documentNumber}
            name="documentNumber"
            onChange={onChange}
            variant="outlined"
            label="Ingresá tu DNI*"
            id="documentnumber"
            error={!!errors.documentNumber}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={data.linkedIn}
            name="linkedIn"
            onChange={onChange}
            variant="outlined"
            label="Ingresá tu URL de LinkedIn"
            id="linkedin"
            error={!!errors.linkedIn}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <InputFile
            value={data.curriculum}
            name={'curriculum'}
            setData={setData}
            label={'Subir currículum'}
            error={errors.curriculum}
          />
        </Grid>
      </Grid>

      <Grid>
        <Responsive data={data} setData={setData} errors={errors} />
      </Grid>
    </Grid>
  );
}
