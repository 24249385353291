import { isAfter } from 'date-fns';
import * as Yup from 'yup';

export default Yup.object().shape({
  studentId: Yup.number().required('Estudiante requerido'),
  day: Yup.string().required('Debes elegir un día'),
  start: Yup.date()
    .typeError('Hora inválida')
    .required('Se requiere el horario de comienzo'),
  end: Yup.date()
    .typeError('Hora inválida')
    .required('Se requiere el horario de finalización'),
  startDate: Yup.date()
    .typeError('Fecha inválida')
    .required('Requerimos la fecha de comienzo'),
  endDate: Yup.date()
    .typeError('Fecha inválida')
    .test('dates_test', 'La fecha es incorrecta', function (value) {
      const { startDate } = this.parent;
      return startDate && value
        ? isAfter(new Date(value), new Date(startDate))
        : false;
    })
    .required('Requerimos la fecha de finalización'),
});
