import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CircularSpinner from '../Spinners/CircularSpinner';

export interface ProtectedRouteProps extends RouteProps {
  role: string;
  restrictedPath: string;
  authenticationPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const session = useSelector((state) => state.SessionReducer);
  if (session.isLoading) return <CircularSpinner />;
  let redirectPath = '';
  if (!session?.isAuthenticated) {
    redirectPath = props.authenticationPath;
  }
  if (session?.isAuthenticated && !session.roles?.includes(props.role)) {
    redirectPath = props.restrictedPath;
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;
