import Swal from 'sweetalert2';

import {
  getVolunteersByAvailability,
  getStudentsByAvailability,
  deleteTeam,
  addLinkage,
} from '../../../../service/api';

export const getStudents = (volunteerId) =>
  getStudentsByAvailability(volunteerId).then(({ data }) =>
    data
      ?.filter(
        ({ status }) => status === 'active'
      )
      .map((s) => ({
        id: s.id,
        firstName: s.user?.firstName || '-',
        lastName: s.user?.lastName || '-',
        email: s.user?.email || '-',
        mobilePhone: s.mobilePhone,
        status: s.status,
        availabilities: s.user?.availabilities || [],
        subjects: s.subjects,
        totalAvailabilityHours: s.totalAvailabilityHours,
        amountCompatibilityHours: s.amountCompatibilityHours,
        volunteerPercentage: s.volunteerPercentage,
        studentPercentage: s.studentPercentage,
        averagePercentage: s.averagePercentage,
      }))
  );

export const getVolunteers = () =>
  getVolunteersByAvailability().then(({ data }) =>
    data
      ?.filter(({ status }) => status === 'accepted')
      .map((v) => ({
        id: v.id,
        userId: v.user?.id || '-',
        lastName: v.user?.lastName || '-',
        firstName: v.user?.firstName || '-',
        email: v.user?.email || '-',
        mobilePhone: v.mobilePhone,
        status: v.status,
        availabilities: v.user?.availabilities || [],
        subjects: v.subjects,
        students: v.students,
        totalAvailabilityHours: v.totalAvailabilityHours,
        team: v.students
          .map(
            (student) =>
              `${student.user?.firstName ?? ''} ${student.user?.lastName ?? ''}`
          )
          .join(', '),
      }))
  );

export const teamDesvinculation = (volunteer, student): Promise<any> => {
  return Swal.fire({
    title: `Deseas desvincular a ${volunteer.firstName}  ${volunteer.lastName} y  ${student.user?.firstName}  ${student.user?.lastName}`,
    showDenyButton: true,
    confirmButtonText: `Si, desvincular`,
    denyButtonText: `No`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      return deleteTeam(volunteer.id, student.id).then(() =>
        Swal.fire('Actualizado!', '', 'success')
      );
    } else if (result.isDenied) {
      Swal.fire('No se realizaron cambios', '', 'info');
    }
  });
};

export const addRelation = (volunteer, student) => {
  if (volunteer && student) {
    const volunteerStudentsIds = volunteer.students.map(({ id }) => id);
    if (volunteerStudentsIds.includes(student.id)) {
      return Swal.fire('Ups', 'Este jugador ya pertenece al equipo', 'info');
    } else {
      return Swal.fire({
        title: '¿Seguro que quieres asignar este equipo?',
        text: `Vinculando al entrenador: ${volunteer.firstName} con jugador: ${student.firstName}`,
        showCancelButton: true,
        showConfirmButton: true,
      }).then((value) => {
        if (value.isConfirmed) {
          return addLinkage(volunteer.id, student.id)
            .then(() => {
              return Swal.fire(
                'Equipo asignado correctamente',
                'Puedes seguir asignado otros equipos',
                'success'
              );
            })
            .catch(() => {
              Swal.fire(
                'Hubo un problema',
                'No pudo asignarse el equipo',
                'error'
              );
            });
        }
      });
    }
  }
};

export const getCompatibilityHours = (availabilityA, availabilityB) => {
  const daysA = Object.keys(availabilityA);
  let amountCompatibilityHours = 0;
  daysA.forEach((day) =>
    availabilityA[day]?.forEach(([startA, endA]) =>
      availabilityB[day]?.forEach(([startB, endB]) => {
        if (Math.max(startA, startB) <= Math.min(endA, endB))
          amountCompatibilityHours +=
            Math.min(endA, endB) - Math.max(startA, startB);
      })
    )
  );
  return amountCompatibilityHours;
};
