import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useHistory } from 'react-router-dom';
import CreateClassButton from './CreateClassButton/CreateClassButton';
import { getLessons, getMe } from '../../../../service/api';
import { Grid, Typography } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

const translate = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
};

function ClassVolunteer() {
  const history = useHistory();
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    getMe()
      .then(({ data: user }) => getLessons(user.volunteerId))
      .then(({ data: lessons }) => {
        setLessons(
          lessons.map((lesson) => ({
            ...lesson,
            studentId: lesson.student.id,
            start: `${lesson.start}:00`,
            end: `${lesson.end}:00`,
            firstName: lesson.student.user?.firstName,
            lastName: lesson.student.user?.lastName,
            startDate: new Date(lesson.startDate).toLocaleDateString('es-AR'),
            endDate: new Date(lesson.endDate).toLocaleDateString('es-AR'),
            day: translate[lesson.day] ? translate[lesson.day] : lesson.day,
          }))
        );
      });
  }, []);

  return (
    <Grid spacing={5}>
      <Grid spacing={5} style={{ textAlign: 'center' }}>
        <Grid item>
          <Typography
            variant="h3"
            gutterBottom
            style={{ fontFamily: '  Downward' }}
          >
            Clases
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: '  poppins' }}
          >
            En esta sección podrás ver y emitir informes de tus clases activas.
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <Grid style={{ alignItems: 'center' }} container>
                <Grid item xs={9}>
                  <MTableToolbar {...props} />
                </Grid>
                <Grid item xs={3}>
                  <CreateClassButton />
                </Grid>
              </Grid>
            ),
          }}
          title="Mis Clases"
          columns={[
            {
              title: 'Nombre',
              field: 'firstName',
              customSort: (a, b) =>
                a.firstName
                  ?.toLowerCase()
                  .localeCompare(b.firstName?.toLowerCase()),
            },
            {
              title: 'Apellido',
              field: 'lastName',
              customSort: (a, b) =>
                a.lastName
                  ?.toLowerCase()
                  .localeCompare(b.lastName?.toLowerCase()),
            },
            { title: 'Dia', field: 'day' },
            { title: 'Hora de inicio', field: 'start' },
            { title: 'Hora de finalización', field: 'end' },
            { title: 'Fecha de inicio', field: 'startDate' },
            { title: 'Fecha de finalización', field: 'endDate' },
          ]}
          data={lessons}
          actions={[
            {
              icon: () => <PostAdd color="primary" />,
              tooltip: 'Completar informe',
              onClick: (_event, rowData) =>
                history.push('/entrenador/clases/feedback/' + rowData.id),
            },
          ]}
          options={{
            exportButton: true,
            actionsColumnIndex: -1,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsPerPage: 'Filas por página',
              lastTooltip: 'Última página',
              firstTooltip: 'Primera página',
              nextTooltip: 'Página siguiente',
              previousTooltip: 'Página anterior',
              labelRowsSelect: 'Filas',
            },
            header: {
              actions: 'Gestión',
            },
            toolbar: {
              exportTitle: 'Exportar',
              searchPlaceholder: 'Buscar',
              searchTooltip: 'Buscar',
            },
            body: {
              emptyDataSourceMessage: 'Todavía no hay clases creadas.',
              filterRow: {
                filterTooltip: 'Filtro',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ClassVolunteer;
