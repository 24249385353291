import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

const ViewAdmin = () => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{
        height: '70%',
        textAlign: 'center',
      }}
    >
      <Box>
        <Typography
          variant="h3"
          gutterBottom
          style={{ fontFamily: '  Downward' }}
        >
          Bienvenido al panel de Administración
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          style={{ fontFamily: '  poppins' }}
        >
          ¡Acá podrás ver los datos de los entrenadores y jugadores, sus clases
          y más!
        </Typography>
        <Typography variant="h6" style={{ fontFamily: '  poppins' }}>
          Podés navegar por los íconos de tu panel izquierdo.
        </Typography>
      </Box>
    </Grid>
  );
};

export default ViewAdmin;
