import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: 16,
    margin: 'auto',
  },
  container: {
    justifyContent: 'center',
  },
  image: {
    width: 95,
    height: 95,
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: 75,
    },
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  tipography: {
    fontFamily: 'Poppins, sans serif',
  },
  responsiveImage: {
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
  responsiveText: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

export default useStyle;
