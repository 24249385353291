import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';

import VolunteersTable from './Tables/VolunteersTable';
import StudentsTable from './Tables/StudentsTable';

import VolunteerCard from './VolunteerCard';
import StudentCard from './StudentCard';
import {
  getStudents,
  getVolunteers,
  addRelation,
  teamDesvinculation,
} from './functions';
import Calendar from './Calendar/Calendar';
import CircularProgressWithLabel from './CircularProgressWithLabel';

import ErrorIcon from '@material-ui/icons/Error';
import useStyles from './styles';

const TeamAssignment = () => {
  const classes = useStyles();
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [shouldRefreshData, setRefreshData] = useState<boolean>(false);

  const [volunteers, setVolunteers] = useState({
    loading: true,
    data: [],
  });
  const [students, setStudents] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    getStudents(selectedVolunteer?.id).then((data) => {
      setStudents({
        loading: false,
        data,
      });
      setSelectedStudent((selected) =>
        selected ? data.find((s) => s.id === selected.id) : null
      );
    });
    getVolunteers().then((data) => {
      setVolunteers({
        loading: false,
        data,
      });
      setSelectedVolunteer((selected) =>
        selected ? data.find((v) => v.id === selected.id) : null
      );
    });
  }, [selectedVolunteer?.id, shouldRefreshData]);

  const handleRefreshData = () => setRefreshData((state) => !state);
  const handleTeamDesvinculation = (volunteer, student) =>
    teamDesvinculation(volunteer, student)?.then(handleRefreshData);
  const onSelectVolunteer = (volunteer) => setSelectedVolunteer(volunteer);
  const onSelectStudent = (student) => setSelectedStudent(student);
  const onClickAddRelation = () =>
    addRelation(selectedVolunteer, selectedStudent)?.then(handleRefreshData);

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item spacing={5} style={{ textAlign: 'center' }}>
        <Typography
          variant="h3"
          gutterBottom
          style={{ fontFamily: 'Downward' }}
        >
          Asignación de equipos
        </Typography>
        <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins' }}>
          En esta sección podrás asignar y desvincular equipos.
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6} style={{ padding: 10 }}>
          <VolunteersTable
            data={volunteers.data}
            onSelectVolunteer={onSelectVolunteer}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: 10 }}>
          <StudentsTable
            data={students.data}
            onSelectStudent={onSelectStudent}
          />
        </Grid>
      </Grid>
      <Grid
        item
        justify="center"
        className={classes.cardsContainer}
        container
        direction="row"
        spacing={5}
        wrap="wrap"
      >
        <Grid container item md={3} justify="center">
          <VolunteerCard
            handleTeamDesvinculation={handleTeamDesvinculation}
            volunteer={selectedVolunteer}
            compatibilityPercentage={selectedStudent?.volunteerPercentage}
          />
        </Grid>
        <Grid item md={3} sm={8} container direction="column" spacing={4}>
          <Grid item md={12} container justify="center">
            <Paper style={{ borderRadius: '50%', width: 150, height: 150 }}>
              <CircularProgressWithLabel
                size={150}
                style={{
                  color: `${
                    selectedStudent?.averagePercentage < 33
                      ? 'red'
                      : selectedStudent?.averagePercentage < 66
                      ? 'yellow'
                      : 'green'
                  }`,
                }}
                value={selectedStudent?.averagePercentage}
              />
            </Paper>
          </Grid>
          <Grid
            item
            md={12}
            container
            style={{ alignItems: 'center' }}
            justify="center"
          >
            {selectedStudent?.averagePercentage === 0 &&
              selectedStudent &&
              selectedVolunteer && <ErrorIcon style={{ color: 'red' }} />}
            <Typography display="inline">
              {!selectedVolunteer && !selectedStudent
                ? 'Seleccioná entrenador y jugador'
                : 'Compatibilidad promedio'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={3} justify="center">
          <StudentCard
            student={selectedStudent}
            compatibilityPercentage={selectedStudent?.studentPercentage}
          />
        </Grid>
        <Grid container item md={3} justify="center">
          <Button
            style={{ width: 200, height: 200, borderRadius: '50%' }}
            fullWidth
            variant="contained"
            color="primary"
            disabled={!selectedStudent || !selectedVolunteer}
            onClick={onClickAddRelation}
          >
            Asignar equipo
          </Button>
        </Grid>
      </Grid>
      <Grid item justify="center" container>
        {selectedVolunteer && selectedStudent && (
          <Calendar student={selectedStudent} volunteer={selectedVolunteer} />
        )}
      </Grid>
    </Grid>
  );
};

export default TeamAssignment;
