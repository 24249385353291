import React, { Fragment } from 'react';
import {
  Divider,
  Grid,
  ListItem,
  Typography,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

import PageviewIcon from '@material-ui/icons/Pageview';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import useStyles from './styles';

const VolunteerCard = ({
  volunteer,
  handleTeamDesvinculation,
  compatibilityPercentage,
}: {
  volunteer: any;
  handleTeamDesvinculation: any;
  compatibilityPercentage?: number;
}) => {
  const classes = useStyles();
  return (
    <Paper
      elevation={3}
      style={{
        flexGrow: 1,
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              title={
                volunteer ? `${volunteer.firstName}${volunteer.lastName}` : ''
              }
              alt={`Avatar`}
            >
              {volunteer ? (
                `${volunteer.firstName[0].toUpperCase()}${volunteer.lastName[0].toUpperCase()}`
              ) : (
                <PageviewIcon color="primary" />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            secondary={
              !volunteer ? ' Seleccioná alguno para ver su compatibilidad' : ''
            }
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body1"
                  style={{ display: 'inline' }}
                  color="textSecondary"
                >
                  Entrenador seleccionado:
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  style={{ display: 'inline' }}
                  color="textPrimary"
                >
                  {volunteer
                    ? ` ${volunteer?.firstName} ${volunteer?.lastName}`
                    : ' Sin elegir'}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="middle" />
        {compatibilityPercentage !== null && volunteer && (
          <Fragment>
            <ListItem>
              <ListItemIcon>
                <CircularProgressWithLabel
                  style={{
                    color:
                      compatibilityPercentage < 33
                        ? 'red'
                        : compatibilityPercentage < 66
                        ? 'yellow'
                        : 'green',
                  }}
                  value={compatibilityPercentage}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ display: 'inline' }}
                    color={volunteer ? 'textPrimary' : 'textSecondary'}
                  >
                    Satisfabilidad horaria porcentual
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon>
                {compatibilityPercentage < 33 ? (
                  <ErrorIcon style={{ color: 'red' }} />
                ) : compatibilityPercentage < 66 ? (
                  <CheckCircleIcon style={{ color: 'yellow' }} />
                ) : (
                  <FavoriteIcon style={{ color: 'green' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ display: 'inline' }}
                    color={volunteer ? 'textPrimary' : 'textSecondary'}
                  >
                    {volunteer
                      ? compatibilityPercentage < 33
                        ? 'No es compatible'
                        : compatibilityPercentage < 66
                        ? 'La compatibilidad es media'
                        : 'La compatibilidad es excelente!!'
                      : 'Sin selección'}
                  </Typography>
                }
              />
            </ListItem>
          </Fragment>
        )}
      </List>
      <Grid justify="center">
        {volunteer && (
          <Paper component="ul" className={classes.root}>
            {volunteer?.students?.length ? (
              volunteer.students.map((student) => (
                <li key={student.id}>
                  <Chip
                    icon={<TagFacesIcon />}
                    label={`${student.user?.firstName} ${student.user?.lastName}`}
                    onDelete={() =>
                      handleTeamDesvinculation(volunteer, student)
                    }
                    className={classes.chip}
                  />
                </li>
              ))
            ) : (
              <li>
                <Typography color="textSecondary">
                  Aún sin Estudiantes
                </Typography>
              </li>
            )}
          </Paper>
        )}
      </Grid>
    </Paper>
  );
};

export default VolunteerCard;
