import React from 'react';
import { Grid, ButtonBase, Typography } from '@material-ui/core';
import useStyle from './style';
import Image from '../../../static/images/imageHeaderVolunteer.png';
import titles from './titles';

export default function HeaderForm({ step }) {
  const classes = useStyle();
  return (
    <div>
      <div className={classes.paper}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item className={classes.responsiveImage}>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt="complex" src={Image} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs className={classes.responsiveText}>
                {titles[step] && (
                  <Typography className={classes.tipography} variant="h5">
                    {titles[step].title}
                  </Typography>
                )}
                {titles[step] && (
                  <Typography
                    className={classes.tipography}
                    variant="subtitle2"
                    style={{ lineHeight: 1.3 }}
                  >
                    {titles[step].subtitle}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
