import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './style';
import bicycleSinFondo from '../../../static/images/croppedBicycleSinFondo.png';
import png from '../../../static/images/Capa3.png';

export default function Picture() {
  const classes = useStyles();

  return (
    <Grid className={classes.backgroundPictureContainer}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.backgroundPicture}
      >
        <img
          src={bicycleSinFondo}
          className={classes.bicycleImage}
          alt={'...'}
        />
        <img src={png} className={classes.png} alt={'...'} />
      </Grid>
      <Grid
        className={classes.sumate}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.innerSumate}>
          <Typography variant="h1" className={classes.sumateTitle}>
            Sumate!
          </Typography>
          <Typography
            variant="body1"
            align="inherit"
            noWrap={false}
            gutterBottom={true}
            className={classes.sumateText}
          >
            Trabajemos juntos por la inclusión social y el desarrollo humano de
            niños y adolescentes en estado de vulnerabilidad.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
