import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './style';

export default function Picture() {
  const classes = useStyles();

  return (
    <Grid className={classes.backgroundPictureContainer}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.backgroundPicture}
      >
        <img
          src={
            'https://static.wixstatic.com/media/2e00ff_1d85c9989f6643c0aa9e3117e1113def~mv2.jpg/v1/fill/w_980,h_618,al_c,q_85,usm_0.66_1.00_0.01/2e00ff_1d85c9989f6643c0aa9e3117e1113def~mv2.webp'
          }
          className={classes.bicycleImage}
          alt={'...'}
        />
      </Grid>
      <Grid
        className={classes.sumate}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.innerSumate}>
          <Typography variant="h1" className={classes.sumateTitle}>
            Sumate!
          </Typography>
          <Typography
            variant="body1"
            align="inherit"
            noWrap={false}
            gutterBottom={true}
            className={classes.sumateText}
          >
            Trabajemos juntos por la inclusión social y el desarrollo humano de
            niños y adolescentes en estado de vulnerabilidad.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
