import React from 'react';
import { Link } from 'react-router-dom';
import useStyles from './style';
import { TextField, FormControl, Grid, Box } from '@material-ui/core';
import Header from './Header/Header';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Buttom from '../Buttom/Buttom';
export default function Form({ setUser, user }) {
  const classes = useStyles();

  const onChange = (e) => {
    setUser((user) => ({
      ...user,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <Grid className={classes.root}>
      <Header />
      <Box boxShadow={3} className={classes.container}>
        <Box
          fontWeight="fontWeightBold"
          className={classes.tipography}
          fontSize={25}
          m={1}
        >
          Bienvenido!
        </Box>
        <FormControl className={classes.gral}>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item className={classes.input}>
              <AccountCircle />
              <TextField
                name="email"
                id="standard-basic"
                onChange={onChange}
                label="Ingresá tu email"
                value={user?.email}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item className={classes.input}>
              <VpnKeyIcon />
              <TextField
                id="input-with-icon-grid"
                name="password"
                onChange={onChange}
                type="password"
                label="Ingresá tu contraseña"
                value={user?.password}
              />
            </Grid>
          </Grid>
        </FormControl>
        <Buttom user={user} />
        <Link className={classes.pass} to="/password">
          ¿Olvidaste tu contraseña?
        </Link>
      </Box>
    </Grid>
  );
}
