import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './App.css';
import Password from './components/LogIn/Password/ForgotPassword/Password';
import VolunteerForm from './components/VolunteerForm/VolunteerForm';
import AdminContainer from './containers/AdminContainer/AdminContainer';
import Reset from './components/LogIn/Password/ResetPassword/Reset';
import Contact from './components/Contact/Contact';
/* import Profile from "./components/AdminComponents/ProfileVolunteer/Profile"; */
import Home from './components/Home/Home';
import LogIn from './components/LogIn/LogIn';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import ViewVolunteer from './containers/VolunteerContainer/VolunteerContainer';
import { getMe } from './service/api';
import { sessionLogin, sessionLoading } from './redux/session/actions';

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    getMe().then((user) => {
      if (!user.data?.id) dispatch(sessionLoading(false));
      else dispatch(sessionLogin(user.data));
    });
  }, [dispatch]);
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/resetPassword" component={Reset} />
      <Route exact path="/password" component={Password} />
      <Route exact path="/postularme" component={VolunteerForm} />
      <Route exact path="/contacto" component={Contact} />
      <Route exact path="/logIn" component={LogIn} />
      <ProtectedRoute
        path="/admin"
        authenticationPath="/logIn"
        restrictedPath="/logIn"
        role="admin"
        render={() => <AdminContainer />}
      />
      <ProtectedRoute
        path="/entrenador"
        authenticationPath="/logIn"
        restrictedPath="/logIn"
        role="volunteer"
        render={() => <ViewVolunteer />}
      />
    </Switch>
  );
}
