import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function TimeClass({ VoluntDataSubjects }) {
  const traduccion = {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
  };
  const traductionOrder = Object.keys(traduccion);
  return (
    <Box>
      <Typography gutterBottom variant="h6" component="h3">
        Días seleccionados:
        <Box>
          {VoluntDataSubjects.availabilities
            .sort(
              (a, b) =>
                traductionOrder.indexOf(a.day) - traductionOrder.indexOf(b.day)
            )
            .map((a, i) => (
              <Box key={i}>
                <Typography variant="subtitle2">
                  {`${traduccion[a.day] ? traduccion[a.day] : a.day} ---> ${
                    a.start
                  }:00 a ${a.end}:00`}{' '}
                </Typography>
              </Box>
            ))}
        </Box>
      </Typography>
    </Box>
  );
}
