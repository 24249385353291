const MAX_FILE_SIZE = 3000 * 1024;
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export interface errorInterface {
  [0]: {
    firstName?: string;
    lastName?: string;
    birthDate?: string;
    mobilePhone?: string;
    email?: string;
  };
  [1]: {
    documentNumber?: string;
    documentFront?: any;
    documentBack?: any;
    curriculum?: any;
    linkedIn?: string;
  };
  [2]: {
    availabilities?: string;
  };
  [3]: {
    subjects?: string;
  };
  some: (fun: any) => any;
}

export default function validate(data) {
  const err: errorInterface = [{}, {}, {}, {}];

  if (!data.documentNumber) {
    err[1].documentNumber = 'Requerimos de un DNI';
  } else if (!Number(data.documentNumber) || data.documentNumber.length < 6) {
    err[1].documentNumber = 'El DNI es inválido';
  } else if (data.documentNumber.length > 9) {
    err[1].documentNumber = 'El DNI es demasiado extenso';
  }

  if (data.curriculum && data.curriculum.size > MAX_FILE_SIZE) {
    err[1].curriculum = 'El archivo supera los 3MB';
  } else if (
    data.curriculum &&
    !SUPPORTED_FORMATS.includes(data.curriculum.type)
  ) {
    err[1].curriculum = 'No se admite este formato.';
  }

  if (
    data.linkedIn &&
    !/https:\/\/www\.linkedin\.com\/in\/\w+\/?/.test(data.linkedIn)
  ) {
    err[1].linkedIn = 'El linkedIn no es una url de perfil válida';
  }

  if (
    !Object.keys(data.availabilities).some(
      (day) => !!data.availabilities[day].length
    )
  ) {
    err[2].availabilities = 'Marcá al menos un rango horario';
  }

  if (!data.subjects.length) {
    err[3].subjects =
      'Seleccioná al menos una materia en la que podés dar asistencia';
  }

  return err;
}
