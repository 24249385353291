import React from 'react';
import Button from '@material-ui/core/Button';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

import useStyles from './style';

export default function InputFile({ name, value, setData, label, error }) {
  const classes = useStyles();

  const onChange = (e) => {
    setData((data) => ({
      ...data,
      [name]: e.target.files[0],
    }));
  };

  return (
    <div className={classes.root}>
      <input
        id={`${name}-file`}
        type="file"
        accept="image/png, .jpg, .jpeg, application/pdf"
        style={{
          display: 'none',
        }}
        onChange={onChange}
      />

      <label htmlFor={`${name}-file`}>
        <Button
          classes={{
            root: classes.button,
            label: classes.label,
          }}
          variant="outlined"
          component="span"
        >
          <AddToPhotosOutlinedIcon
            style={{ marginRight: '5px', color: '#DEDCD8' }}
          />
          {label}
        </Button>
      </label>
      <div style={{ fontFamily: 'Poppins', color: error ? 'red' : 'black' }}>
        {value ? error || value.name : error || 'No hay archivo cargado'}
      </div>
    </div>
  );
}
