import React from 'react';
import { Link } from 'react-router-dom';

import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import useStyles from './style';
import Image from '../../static/images/imageHeaderVolunteer.png';

export default function Home() {
  const classes = useStyles();

  let data = [
    { text: ' Postularme ', route: '/postularme' },
    { text: 'Iniciar sesión', route: '/logIn' },
    // { text: "Admin", route: "/admin" },
    { text: 'Contacto', route: '/contacto' },
  ];
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <ButtonBase>
          <img alt="potrero-logo" className={classes.logoImg} src={Image} />
        </ButtonBase>
      </Grid>
      <Grid container className={classes.buttonsContainer}>
        {data.map((button) => (
          <Link
            key={button.text}
            className={`font ${classes.link}`}
            to={button.route}
          >
            <Box className={classes.container}>{button.text}</Box>
          </Link>
        ))}
      </Grid>
    </Grid>
  );
}
