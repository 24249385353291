import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  overflow: {
    'overflow-x': 'none',
  },
  center: {
    alignItems: 'center',
  },
});

export default useStyles;
