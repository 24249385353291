import React from 'react';
import DashboardContainer from '../../components/Admin/DashboardAdmin/DashboardContainer';
import { Box } from '@material-ui/core';

import useStyles from './Style';

const AdminContainer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <DashboardContainer />;
    </Box>
  );
};

export default AdminContainer;
