import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  badgeActive: {
    '& .MuiBadge-badge': {
      background: 'green',
    },
  },
  badgeInactive: {
    '& .MuiBadge-badge': {
      background: 'lightgreen',
    },
  },
  badgeRejected: {
    '& .MuiBadge-badge': {
      background: 'red',
    },
  },
  badgePending: {
    '& .MuiBadge-badge': {
      background: 'yellow',
    },
  },
});

export default useStyles;
