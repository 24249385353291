import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { week, translate } from '../../../../utils/week';

export default function WeekButtons({ day, setDay }) {
  const onChange = (e, newDay) => {
    setDay(newDay);
  };

  return (
    <BottomNavigation value={day} onChange={onChange} showLabels>
      {week.map((d, i) => (
        <BottomNavigationAction
          value={d}
          label={translate[d]}
          key={i}
          style={{ minWidth: '.1em' }}
        />
      ))}
    </BottomNavigation>
  );
}
