import React from 'react';
import { Box, Typography } from '@material-ui/core';

const SubjectsClass = ({ VoluntDataSubjects }) => {
  return (
    <Box>
      <Typography gutterBottom variant="h6" component="h3">
        Materias seleccionadas:
        <Box>
          {VoluntDataSubjects.subjects.map((s, i) => (
            <Box key={i}>
              <Typography variant="subtitle2">
                {s.subjectName.subjectName} - Grado: {s.grade.grade}
              </Typography>
            </Box>
          ))}
        </Box>
      </Typography>
    </Box>
  );
};

export default SubjectsClass;
