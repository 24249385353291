import React from 'react';
import Table from '@material-ui/core/Table';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core/';

import useStyles from './Style';
import { hours, cells } from './Logic';

export default function BasicTable({ data, day }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.overflow}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Voluntarios</TableCell>
            {hours.map((hora) => (
              <TableCell key={hora}>
                <label style={{ position: 'relative', left: '-35%' }}>
                  {hora < 10 && '0'}
                  {hora + ':00'}
                </label>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((volunteer) => (
            <TableRow key={volunteer.firstName}>
              <TableCell component="th" scope="row">
                {`${volunteer.firstName} ${volunteer.lastName}`}
              </TableCell>
              {hours.map((hora) => (
                <TableCell
                  key={hora}
                  style={{
                    padding: 0,
                    borderLeft: 'solid 1px #e0e0e0',
                    borderRight: 'solid 1px #e0e0e0',
                  }}
                >
                  <div className={cells(hora, volunteer, day, classes)}></div>
                  {null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
