import React from 'react';
import MaterialTable from 'material-table';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

import TimeClass from '../../Volunteers/VolunteerProfile/InfoOfClass/TimeClass';
import SubjecsClass from '../../Volunteers/VolunteerProfile/InfoOfClass/SubjectsClass';

function StudentsTable({ data, onSelectStudent }) {
  const [value, setValue] = React.useState('');

  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <MaterialTable
      title="Jugadores"
      columns={[
        { title: 'Apellido', field: 'lastName', align: 'center' },
        { title: 'Nombre', field: 'firstName', align: 'center' },
        {
          title: 'Disponibilidad de horas',
          field: 'totalAvailabilityHours',
          align: 'center',
        },
      ]}
      data={data}
      options={{
        exportButton: true,
      }}
      actions={[
        {
          icon: 'save',
          tooltip: 'Save User',
          onClick: (event, rowData) => onSelectStudent(rowData),
        },
      ]}
      components={{
        Action: (props) => (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={value}
              onChange={(event) => {
                props.action.onClick(event, props.data);
                handleRadioChange(event);
              }}
            >
              <FormControlLabel
                value={props.data.email}
                control={<Radio />}
                label=""
              />
            </RadioGroup>
          </FormControl>
        ),
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsPerPage: 'Filas por página',
          lastTooltip: 'Última página',
          firstTooltip: 'Primera página',
          nextTooltip: 'Página siguiente',
          previousTooltip: 'Página anterior',
          labelRowsSelect: 'Filas',
        },
        body: {
          emptyDataSourceMessage: 'No se han encontrado estudiantes',
          filterRow: {
            filterTooltip: 'Filtro',
          },
        },
        toolbar: {
          searchPlaceholder: 'Buscar Jugador',
        },
        header: {
          actions: '',
        },
      }}
      onSelectionChange={onSelectStudent}
      detailPanel={(rowData) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <TimeClass VoluntDataSubjects={rowData} />
            <SubjecsClass VoluntDataSubjects={rowData} />
          </div>
        );
      }}
    />
  );
}

export default StudentsTable;
