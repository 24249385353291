import useStyles from './styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export default function SocialMedia() {
  const classes = useStyles();
  return (
    <div className={classes.instagram}>
      <a
        className={classes.redes}
        href="https://www.instagram.com/fundacionelpotrero/"
      >
        <InstagramIcon style={{ fontSize: 40, onBlur: '@instagram' }} />
      </a>

      <a
        className={classes.redes}
        href={`mailto:${process.env.REACT_APP_ADMIN_EMAIL}`}
      >
        <MailOutlineIcon style={{ fontSize: 40 }} />{' '}
      </a>

      <a
        className={classes.redes}
        href="https://www.facebook.com/fundacionelpotrero"
      >
        <FacebookIcon style={{ fontSize: 40 }} />
      </a>

      <a className={classes.redes} href="https://wa.me/5491130158351">
        <WhatsAppIcon style={{ fontSize: 40 }} />
      </a>

      <a
        className={classes.redes}
        href="https://www.linkedin.com/company/fundacionelpotrero/"
      >
        <LinkedInIcon style={{ fontSize: 40 }} />
      </a>
    </div>
  );
}
