import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonSubject: {
    textTransform: 'none',
    fontFamily: 'Poppins, sans serif',
    boxShadow: '1px 2px 3px #999',
    margin: '.2rem',
    height: '2.5rem',
    '&:hover': {
      transition: '0.5s',
      backgroundColor: '#8cc63e',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: '#8cc63e',
      color: 'white',
    },
  },

  buttonPicked: {
    textTransform: 'none',
    fontFamily: 'Poppins, sans serif',
    boxShadow: '1px 2px 3px #999',
    margin: '.1rem',
    backgroundColor: '#8cc63e',
    height: '2.5rem',
    color: 'white',
  },

  buttonCurrentSubject: {
    textTransform: 'none',
    fontFamily: 'Poppins, sans serif',
    margin: '.1rem',
    backgroundColor: '#3f51b5',
    color: 'white',
  },

  containerSubjects: {
    width: '100%',
    height: 'auto',
    padding: '.5rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default useStyles;
