import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import OptionalData from './OptionalData/OptionalData';

import useStyles from './style';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Responsive({ data, setData, errors }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="documento" {...a11yProps(0)} />
          <Tab label="datos opcionales" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <div className={classes.form}>
            <TextField
              value={data.documentNumber}
              name="documentNumber"
              onChange={onChange}
              label="Número de Documento *"
              id="standard-size-small"
              size="small"
              error={errors.documentNumber}
            />
            {/* 
            Not required in postulation. TODO: migrate to volunteers profile view
            <InputFile
              value={data.documentFront}
              name={'documentFront'}
              setData={setData}
              label={'Subir frente del DNI *'}
              error={errors.documentFront}
            />
            <InputFile
              value={data.documentBack}
              name={'documentBack'}
              setData={setData}
              label={'Subir dorso del DNI *'}
              error={errors.documentBack}
            /> */}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OptionalData
            data={data}
            setData={setData}
            error={errors.curriculum}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
