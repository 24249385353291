import React from 'react';
import Button from '@material-ui/core/Button';
import useStyle from './style';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postLogin } from '../../../service/api';
import { sessionLogin } from '../../../redux/session/actions';
import swal from 'sweetalert';

const Buttom = ({ user }) => {
  const classes = useStyle();

  const dispatch = useDispatch();
  const history = useHistory();

  const logIn = function () {
    postLogin(user).then((rta) => {
      if (rta.data === undefined) {
        swal({
          title: `Error de logIn`,
          text: 'El usuario o la contraseña son inválidos',
          icon: 'warning',
        });
      } else {
        dispatch(sessionLogin(rta.data));
        swal({
          title: `Bienvenido ${rta.data.firstName} ${rta.data.lastName}`,
          text: 'Login exitoso!',
          icon: 'success',
        }).then((e) => {
          if (rta.data.roles[0] === 'admin') {
            history.push(`/admin`);
          } else if (rta.data.roles[0] === 'volunteer') {
            history.push(`/entrenador`);
          } else {
            history.push(`/`);
          }
        });
      }
    });
  };
  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={logIn}
      >
        Iniciar sesión
      </Button>
    </div>
  );
};

export default Buttom;
