import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

export default function WeekDetail({ data, subjects, errors }) {
  const schedules = data.subjects.map((id) => {
    for (const level in subjects) {
      for (const subject in subjects[level]) {
        for (const grade in subjects[level][subject]) {
          if (subjects[level][subject][grade] === id) {
            return { id, subject, grade };
          }
        }
      }
    }
  });

  const schedules_list = schedules.map((s) => (
    <li key={s.id}>{`${s.subject} de ${s.grade}.`}</li>
  ));
  let i = 0;
  return (
    <List dense={true}>
      <ListItem key={i++}>
        <ListItemText
          //primary={data.subjects.map(fullName)}
          primary={schedules_list}
        ></ListItemText>
      </ListItem>
    </List>
  );
}
