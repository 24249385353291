import React from 'react';
import { Link } from 'react-router-dom';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import FaceIcon from '@material-ui/icons/Face';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import useStyles from './style';

// List Volunteers
export const ListVolunteers = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/admin/entrenadores'}>
        <ListItem button>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Entrenadores" />
        </ListItem>
      </Link>
    </List>
  );
};
// Profile of Volunteer
export const StudentsList = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/admin/jugadores'}>
        <ListItem button>
          <ListItemIcon>
            <FaceIcon />
          </ListItemIcon>
          <ListItemText primary="Jugadores" />
        </ListItem>
      </Link>
    </List>
  );
};
export const Admin = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/admin'}>
        <ListItem button>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Administración" />
        </ListItem>
      </Link>
    </List>
  );
};
export const Calendar = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/admin/calendario'}>
        <ListItem button>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText primary="Calendario" />
        </ListItem>
      </Link>
    </List>
  );
};

export const Linkages = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/admin/asignarEquipo'}>
        <ListItem button>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Asignar equipos" />
        </ListItem>
      </Link>
    </List>
  );
};
