import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Days from './Days/Days';
import { translate } from '../../../utils/week';
import useStyles from './style';
import { getCalendar } from '../../../service/api';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box my={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Calendar() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function makeRequest() {
      await getCalendar().then((volunteer) => setData(volunteer.data));
    }
    makeRequest();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item spacing={5} style={{ textAlign: 'center' }}>
        <Typography
          variant="h3"
          gutterBottom
          style={{ fontFamily: 'Downward' }}
        >
          Calendario
        </Typography>
        <Typography variant="h5" gutterBottom style={{ fontFamily: 'poppins' }}>
          En esta sección podrás visualizar el calendario de disponibilidad de
          todos tus entrenadores.
        </Typography>
      </Grid>
      <AppBar position="static" className={classes.center}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          {Object.values(translate).map((day, i) => (
            <Tab key={day} label={day} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>
      {Object.keys(translate).map((day, i) => (
        <TabPanel key={day} value={value} index={i}>
          <Days data={data} day={day} />
        </TabPanel>
      ))}
    </Grid>
  );
}
