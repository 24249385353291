import React from 'react';
import { TextField, Button, Grid, Paper } from '@material-ui/core';
import img from '../../static/images/Home.jpg';
import { sendEmailAdmin } from '../../service/api';
import swal from 'sweetalert';
import Description from './Description';
import SocialMedia from './SocialMedia';

import useStyles from './styles';
import '../Home/font.css';

export default function Contact() {
  const classes = useStyles();

  const [data, setData] = React.useState({
    email: '',
    msj: '',
  });

  const sendData = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };
  const sendEmail = () => {
    if (!data.email) {
      swal({
        title: '¡Esperá!',
        text: 'Por favor, ingresá tu mail.',
        icon: 'warning',
      });
    } else {
      sendEmailAdmin(data).then((rta) => {
        swal({
          title: '¡Excelente!',
          text:
            'Enviaste tu mensaje al equipo de El Potrero, pronto nos comunicaremos con vos',
          icon: 'success',
        });
      });
    }
  };
  return (
    <div className={classes.container}>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        spacing={4}
      >
        <Grid item xs={12} md={12} xl={12}>
          <SocialMedia />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <Description />
        </Grid>
        <Grid item xs={12} md={6} xl={6} style={{ color: 'white' }}>
          <Paper className={classes.card}>
            <TextField
              className={classes.email}
              id="standard-basic"
              label="Ingresá tu email"
              name="email"
              onChange={sendData}
            />
            <TextField
              name="msj"
              className={classes.msj}
              variant="outlined"
              label="Escribí tu mensaje"
              multiline
              rows={3}
              rowsMax={5}
              onChange={sendData}
            />

            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              onClick={sendEmail}
            >
              Enviar
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <img src={img} className={classes.img} alt="Potrero" />
        </Grid>
      </Grid>
    </div>
  );
}
