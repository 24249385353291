import { createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const style = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'white',
      alignItems: 'center',
      height: '30rem',
    },
    message: {
      color: 'black',
    },
  });

export default style;
