import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const useStyles = makeStyles(styles);

function CiruclarSpinner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.message}>Cargando...</Typography>
      <CircularProgress />
    </div>
  );
}

export default CiruclarSpinner;
