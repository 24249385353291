import React from 'react';
import { Link } from 'react-router-dom';
import FaceIcon from '@material-ui/icons/Face';
import ClassIcon from '@material-ui/icons/Class';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from './style';

// List Volunteers
export const Home = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/entrenador'}>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>
      </Link>
    </List>
  );
};

export const Players = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/entrenador/misjugadores'}>
        <ListItem button>
          <ListItemIcon>
            <FaceIcon />
          </ListItemIcon>
          <ListItemText primary="Jugadores" />
        </ListItem>
      </Link>
    </List>
  );
};

export const Classes = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/entrenador/clases'}>
        <ListItem button>
          <ListItemIcon>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText primary="Clases" />
        </ListItem>
      </Link>
    </List>
  );
};

export const Feedbacks = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/entrenador/reportes'}>
        <ListItem button>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Informes" />
        </ListItem>
      </Link>
    </List>
  );
};

export const Profile = () => {
  const classes = useStyles();
  return (
    <List>
      <Link className={classes.link} to={'/entrenador/perfil'}>
        <ListItem button>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Mi Perfil" />
        </ListItem>
      </Link>
    </List>
  );
};
