import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(1),
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    borderRadius: '99px',
  },
  button: {
    marginTop: '30px',
  },
}));

export default useStyles;
