import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import ExamplesReducer from './examples/reducers';
import SessionReducer from './session/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Redux devtools
const composeEnhancers =
  (process.env.REACT_APP_REDUX_DEVTOOLS_ON === 'true' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// Add all reducers here so that they are combined
const rootReducer = combineReducers({
  ExamplesReducer,
  SessionReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
