import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Typography,
  InputLabel,
  Paper,
} from '@material-ui/core/';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Swal from 'sweetalert2';
import RatingLesson from './RatingLesson';
import RadioButton from './RadioButton';
import useStyles from './styles';
import { postFeedback } from '../../../../service/api';

const labels = {
  concentration: 'Concentración',
  internetConnection: 'Conexión de internet',
  performance: 'Desempeño',
  attitude: 'Actitud',
};

export default function LessonEvaluation({
  match: {
    params: { lessonId },
  },
}) {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState({
    date: new Date(new Date().setHours(0)),
    assisted: 'yes',
    attitude: 2,
    concentration: 2,
    internetConnection: 2,
    performance: 2,
    accompanying: null,
    whoAccompanied: '',
    comments: '',
    lessonId: Number(lessonId),
  });

  const onChangeData = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeAssistance = ({ target: { value } }) => {
    if (value !== 'yes')
      setData((data) => ({
        ...data,
        assisted: value,
        attitude: 2,
        concentration: 2,
        internetConnection: 2,
        performance: 2,
        accompanying: null,
        whoAccompanied: '',
      }));
    else
      setData((data) => ({
        ...data,
        assisted: value,
      }));
  };
  const onChangeLessonDate = (lessonDate) => {
    setData((data) => ({ ...data, date: lessonDate }));
  };

  const onSubmit = () => {
    postFeedback({
      ...data,
      date: new Date(new Date(data.date).setHours(0)),
      attitude: Number(data.attitude),
      concentration: Number(data.concentration),
      internetConnection: Number(data.internetConnection),
      performance: Number(data.performance),
      accompanying: data.accompanying === 'true',
    }).then(({ status }) => {
      if (status === 201) {
        Swal.fire(
          'Informe completado con éxito',
          'Muchas gracias',
          'success'
        ).then(() => history.push(`/entrenador/reportes`));
      }
    });
  };

  return (
    <Grid
      container
      spacing={3}
      style={{ textAlign: 'center' }}
      className={classes.root}
    >
      <Grid item>
        <Typography variant="h5">
          Luego de cada encuentro, solicitamos que completes un informe con los
          detalles del mismo:
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Grid
          component={Paper}
          container
          className={classes.paper}
          justify="center"
          spacing={4}
        >
          <Grid item style={{ alignSelf: 'center', textAlign: 'center' }} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="date"
                name="date"
                label="Fecha de la Clase"
                value={data.date}
                onChange={onChangeLessonDate}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                invalidDateMessage="Selecciona una fecha valida"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item sm style={{ alignSelf: 'center', textAlign: 'center' }}>
            <FormControl>
              <InputLabel id="lesson-assistence-label">Asistió</InputLabel>
              <Select
                labelId="lesson-assistence-label"
                id="lesson-assistence-label"
                name="assisted"
                startAdornment={<AssignmentTurnedInIcon />}
                value={data.assisted}
                onChange={onChangeAssistance}
              >
                <MenuItem value={'yes'}>Sí</MenuItem>
                <MenuItem value={'justified'}>NO, Justificada</MenuItem>
                <MenuItem value={'no justified'}>NO, Injustificada</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            sm
            style={{ alignSelf: 'center', textAlign: 'center' }}
          >
            <Grid>
              <RadioButton
                label="¿Estuvo acompañado por alguien?"
                data={data}
                idSi={'true'}
                idNo={'false'}
                onChangeData={onChangeData}
                name="accompanying"
              />
            </Grid>
            {data.assisted === 'yes' && data.accompanying === 'true' && (
              <Grid
                xs={6}
                style={{ alignSelf: 'center', justifySelf: 'center' }}
              >
                <TextField
                  id="whoAccompanied"
                  label="¿Por quién?"
                  onChange={onChangeData}
                  name="whoAccompanied"
                  value={data.whoAccompanied}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.rating}>
        {Object.keys(labels).map((prop, index) => (
          <RatingLesson
            key={index}
            value={data[prop]}
            label={labels[prop]}
            name={prop}
            disabled={data.assisted !== 'yes'}
            onChangeData={onChangeData}
          />
        ))}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {data.assisted === 'yes' ? (
            <Typography>Ingrese un comentario de la clase:</Typography>
          ) : (
            <Typography>
              ¿Por qué no asistió a clase? Deje su comentario.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-multiline-static"
            label="Comentarios"
            multiline
            rows={4}
            variant="outlined"
            onChange={onChangeData}
            name="comments"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={onSubmit}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
