import React, { useState } from 'react';
import useStyles from './style';
import { TextField, FormControl, Grid, Box } from '@material-ui/core';
import img from '../../../../static/images/Home.jpg';
import Button from '@material-ui/core/Button';
import Header from '../../Form/Header/Header';
import Query from './Query';
import { useHistory } from 'react-router-dom';
import { putPassword } from '../../../../service/api';
import swal from 'sweetalert';
export default function Password({ location }) {
  const query = Query();
  const history = useHistory();

  const [password, setPassword] = useState({
    password: '',
    password2: '',
    token: query.t,
  });
  var mediumRegex = new RegExp(
    /*  "(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[AZ])(?=.*[az]).*$"  */
    '^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))).*$',
    'g'
    /* "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$" */
  );
  const onChange = (e) => {
    setPassword((password) => ({
      ...password,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClick = () => {
    if (password.password === '') {
      swal({
        title: `Error`,
        text: 'Ingresá una contraseña',
        icon: 'warning',
      });
    } else if (!mediumRegex.test(password.password)) {
      swal({
        title: `Error`,
        text:
          'La contraseña tiene que tener una mayúscula, una minúscula, un número y más de 8 caracteres',
        icon: 'warning',
      });
    } else if (password.password === password.password2) {
      putPassword({ password: password.password, token: password.token }).then(
        (rta) => {
          if (rta) {
            swal({
              title: `Cambio exitoso`,
              text: 'Iniciá sesión con tu nueva contraseña',
              icon: 'success',
            }).then((e) => {
              history.push(`/logIn`);
            });
          }
        }
      );
    } else {
      swal({
        title: `Error`,
        text: 'Las contraseñas no coinciden',
        icon: 'warning',
      });
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={img} className={classes.img} alt="" />
      <Header />
      <Box boxShadow={3} className={classes.box_container}>
        <Box className={classes.tipography} fontSize={20} m={1}>
          Ingresá tu nueva contraseña
        </Box>
        <FormControl className={classes.gral}>
          <Grid item className={classes.inputs}>
            <TextField
              type="password"
              onChange={onChange}
              name="password"
              id="standard-basic"
              label="Ingresá tu contraseña"
            />
            <TextField
              type="password"
              onChange={onChange}
              name="password2"
              id="standard-basic"
              label="Repetí tu contraseña"
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Cambiar contraseña
            </Button>
          </Grid>
        </FormControl>
      </Box>
    </div>
  );
}
