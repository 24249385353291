import React, { Fragment } from 'react';
import {
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
} from '@material-ui/core';

import PageviewIcon from '@material-ui/icons/Pageview';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgressWithLabel from './CircularProgressWithLabel';

export default function SelectedStudent({
  student,
  compatibilityPercentage,
}: {
  student: any;
  compatibilityPercentage?: number;
}) {
  return (
    <Paper
      elevation={3}
      style={{
        flexGrow: 1,
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              title={student ? `${student.firstName}${student.lastName}` : ''}
              alt={`Avatar`}
            >
              {student ? (
                `${student.firstName[0].toUpperCase()}${student.lastName[0].toUpperCase()}`
              ) : (
                <PageviewIcon color="primary" />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            secondary={
              !student ? ' Seleccioná alguno para ver su compatibilidad' : ''
            }
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body1"
                  style={{ display: 'inline' }}
                  color="textSecondary"
                >
                  Jugador seleccionado:
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  style={{ display: 'inline' }}
                  color="textPrimary"
                >
                  {student
                    ? ` ${student?.firstName} ${student?.lastName}`
                    : ' Sin elegir'}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="middle" />
        {compatibilityPercentage !== null && student && (
          <Fragment>
            <ListItem>
              <ListItemIcon>
                <CircularProgressWithLabel
                  style={{
                    color:
                      compatibilityPercentage < 33
                        ? 'red'
                        : compatibilityPercentage < 66
                        ? 'yellow'
                        : 'green',
                  }}
                  value={compatibilityPercentage}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ display: 'inline' }}
                    color={student ? 'textPrimary' : 'textSecondary'}
                  >
                    Satisfabilidad horaria porcentual
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon>
                {compatibilityPercentage < 33 ? (
                  <ErrorIcon style={{ color: 'red' }} />
                ) : compatibilityPercentage < 66 ? (
                  <CheckCircleIcon style={{ color: 'yellow' }} />
                ) : (
                  <FavoriteIcon style={{ color: 'green' }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ display: 'inline' }}
                    color={student ? 'textPrimary' : 'textSecondary'}
                  >
                    {student
                      ? compatibilityPercentage < 33
                        ? 'Este estudiante no es compatible'
                        : compatibilityPercentage < 66
                        ? 'La compatibilidad es media'
                        : 'La compatibilidad es excelente!!'
                      : 'Sin selección'}
                  </Typography>
                }
              />
            </ListItem>
          </Fragment>
        )}
      </List>
    </Paper>
  );
}
