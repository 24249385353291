export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SESSION_LOADING = 'SESSION_LOADING';
export const GET_USER = 'GET_USER';

export const roles = {
  admin: 'admin',
  volunteer: 'volunteer',
  student: 'student',
  tutor: 'tutor',
};
