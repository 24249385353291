import React, { useEffect, useState } from 'react';
import { Switch, Grid, Container } from '@material-ui/core';

import useStyles from '../../Sliders/style';
import { PrettoSlider } from '../../Sliders/style';
import marks from '../../Sliders/marks';

export default function Sliders({ day, value, onChange }) {
  const classes = useStyles();
  const [state, setState] = useState({
    morning: false,
    afternoon: false,
    morningHours: [8, 13],
    afternoonHours: [13, 20],
  });

  useEffect(() => {
    let morning = false;
    let afternoon = false;
    let morningHours = [8, 13];
    let afternoonHours = [13, 20];
    if (value) {
      if (value[0]) {
        if (value[0][1] <= 13) {
          morning = true;
          morningHours = value[0];
        } else {
          afternoon = true;
          afternoonHours = value[0];
        }
      }
      if (value[1]) {
        afternoon = true;
        afternoonHours = value[1];
      }
    }
    setState({
      morning,
      afternoon,
      morningHours,
      afternoonHours,
    });
  }, [value]);

  const toggleState = (e) => {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    });
    let timeRanges = [];
    if (e.target.name === 'morning') {
      if (!state[e.target.name]) timeRanges.push([8, 13]);
    } else {
      if (state['morning']) timeRanges.push(state.morningHours);
    }
    if (e.target.name === 'afternoon') {
      if (!state[e.target.name]) timeRanges.push([13, 20]);
    } else {
      if (state['afternoon']) timeRanges.push(state.afternoonHours);
    }
    onChange(timeRanges);
  };

  const onAfternoonChange = (e, newRange) => {
    if (state.afternoon === newRange) return;
    if (newRange[0] === newRange[1]) return;
    let timeRanges = [];
    if (state.morning) {
      timeRanges.push(state.morningHours);
    }
    if (state.afternoon) {
      timeRanges.push(newRange);
    }
    onChange(timeRanges);
  };

  return (
    <Container>
      <div className={classes.root}>
        <Grid component="label" container alignItems="center" spacing={1}>
          {/* <Grid item>Turno tarde</Grid> */}
          <Grid item>
            <Switch
              checked={state.afternoon}
              onClick={toggleState}
              name="afternoon"
            />
          </Grid>
          <Grid item>Turno tarde</Grid>
        </Grid>
        <PrettoSlider
          disabled={!state.afternoon}
          value={state.afternoonHours}
          onChange={onAfternoonChange}
          min={13}
          max={20}
          marks={marks.afternoon}
          aria-label="pretto slider"
        />
      </div>
    </Container>
  );
}
