import React from 'react';
import {
  FormControl,
  Select,
  Box,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

export default function GradeSelect({ level, setLevel, disabled }) {
  const onSelectLevel = (e) => setLevel(e.target.value);

  return (
    <Box>
      <FormControl>
        <InputLabel id="select-label">Elige nivel</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={level}
          onChange={onSelectLevel}
          disabled={disabled}
        >
          <MenuItem value={'Primaria'}>Primaria</MenuItem>
          <MenuItem value={'Secundaria'}>Secundaria</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
