import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './style';
import Picture from './Picture/Picture';
import Form from './Form/Form';

export default function LogIn() {
  const classes = useStyles();
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const setData = (newData) => {
    setUser(newData);
  };
  return (
    <Grid className={classes.root}>
      <Grid className={classes.container}>
        <Form setUser={setData} user={user}></Form>
      </Grid>
      <Picture />
    </Grid>
  );
}
